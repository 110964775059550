import { memo, MouseEvent, useMemo } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import ConnectorRatingBoxes from 'src/_shared/components/ConnectorRatingBoxes'
import LocationDetailsBox from 'src/_shared/components/LocationDetailsBox'
import LocationHeaderActions from 'src/_shared/components/LocationHeaderActions'
import CloseIcon from 'src/_shared/components/_icons/CloseIcon'
import { Coordinates } from 'src/_shared/types/location'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { formatDistanceDisplay } from 'src/_shared/utils/format'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'

interface LocationModalCardProps {
	location: OmniLocation
	coordinates: Coordinates | null
	onClose?: () => void
	handleCarParkRatesModalOpen: () => void
	handleFavouriteActionError?: (message: string) => void
}

const LocationModalCard = ({
	location,
	coordinates,
	onClose: handleClose,
	handleCarParkRatesModalOpen,
	handleFavouriteActionError
}: LocationModalCardProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const formattedDistance = useMemo((): string | null => {
		if (coordinates && location.coordinates) {
			const { latitude = '0', longitude = '0' } = location.coordinates
			const distanceFromUser = getDistanceBetweenCoordinates(coordinates, {
				latitude: Number(latitude),
				longitude: Number(longitude)
			})
			return formatDistanceDisplay(distanceFromUser)
		}
		return null
	}, [coordinates, location.coordinates])

	const handleClickCard = (): void => {
		if (location.uid) {
			navigate([ScreenRoutePath.Location, location.uid])
		}
	}

	const handleCloseCard = (event: MouseEvent): void => {
		event.stopPropagation()
		handleClose?.()
	}

	return (
		<div
			className="animate-fade-in flex w-full cursor-pointer flex-col space-y-3.5 rounded-modal bg-grayscale-100 p-4 drop-shadow-lg"
			onClick={handleClickCard}
		>
			<div className="flex flex-row items-start justify-between">
				<img className="max-h-10 w-auto max-w-20 object-contain" src={location.operator?.logo} />
				<button onClick={handleCloseCard}>
					<CloseIcon className="h-4 w-4 text-grayscale-600" />
				</button>
			</div>
			{/* Heading */}
			<div className="flex flex-col">
				{/* Location Name + Grouped Icons */}
				<div className="flex flex-row justify-between space-x-4">
					<div>
						<p className="body-2-bold mb-1 text-typography-primary">{location.name ?? '-'}</p>
						{/* Location Address */}
						<p className="caption-3-normal text-left text-typography-tertiary">
							{formattedDistance && (
								<>
									{formattedDistance}
									{' | '}
								</>
							)}
							{location.address ?? '-'}
						</p>
					</div>
					<LocationHeaderActions
						handleFavouriteActionError={handleFavouriteActionError}
						handleCarParkRatesModalOpen={handleCarParkRatesModalOpen}
						location={location}
						hasDirectionsIcon={true}
					/>
				</div>
			</div>
			{/* Sub-Heading */}
			<LocationDetailsBox location={location} className="flex space-x-3" />

			{/* Rating Boxes Section */}
			<ConnectorRatingBoxes
				className="flex space-x-4 overflow-x-scroll"
				evses={location.evses}
				enableFilter={false}
			/>
		</div>
	)
}

const MemoisedLocationModalCard = memo(LocationModalCard)

export default MemoisedLocationModalCard
