import { useCallback } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'
import { OmniLocation } from 'src/_shared/types/omni/location'

const USER_SELECTED_LOCATION = 'userSelectedLocation'

type SelectedLocation = OmniLocation | null

export const useUserSelectedLocation = (): {
	selectedLocation: SelectedLocation
	setSelectedLocation: (selectedLocation: SelectedLocation) => void
} => {
	const [selectedLocation, setSelectedLocation] =
		useSessionStorage<SelectedLocation>(USER_SELECTED_LOCATION)

	const handleSetSelectedLocation = useCallback(
		(location: SelectedLocation) => {
			setSelectedLocation(location)
		},
		[setSelectedLocation]
	)

	return {
		selectedLocation,
		setSelectedLocation: handleSetSelectedLocation
	}
}
