import { SVGProps, memo } from 'react'

const MinusIcon = ({
	fill = 'none',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	stroke = 'currentColor',
	strokeWidth = '1.5',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			stroke={stroke}
			strokeWidth={strokeWidth}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			{...props}
		>
			<path d="M6 12H18" />
		</svg>
	)
}

const MemoisedMinusIcon = memo(MinusIcon)

export default MemoisedMinusIcon
