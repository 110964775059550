/**
 * Executes the provided callback after a specified time.
 * @param {Function} callback The callback to debounce.
 * @param {number} delay The time in milliseconds that the timer should wait for.
 * @returns
 */
export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
	callback: T,
	delay: number
) => {
	let timeoutId: NodeJS.Timeout
	const debouncedCallback = (...args: Parameters<T>): void => {
		clearTimeout(timeoutId)
		timeoutId = setTimeout((): void => {
			callback(...args)
		}, delay)
	}
	return debouncedCallback
}

/**
 * Delays the next execution for a specified amount of time.
 * @param {number} delay The time in milliseconds that the timer should wait for.
 */
export const delay = async (delay: number): Promise<void> => {
	const timeoutId = await new Promise<number>((resolve): number => {
		return setTimeout(resolve, delay)
	})
	clearTimeout(timeoutId)
}
