import { memo } from 'react'
import { ConnectorPowerType } from 'src/_shared/enums/filter'

interface PowerCostBoxProps {
	powerType: ConnectorPowerType
	price: string
}

const PowerCostBox = ({ powerType, price }: PowerCostBoxProps): JSX.Element => {
	return (
		<div className="flex items-center space-x-1">
			<div className="flex h-4 w-4 items-center justify-center rounded-full border border-grayscale-600">
				<p className="caption-1-medium text-typography-tertiary">{powerType}</p>
			</div>
			<p className="caption-3-normal">{price}</p>
		</div>
	)
}

const MemoisedPowerCostBox = memo(PowerCostBox)

export default MemoisedPowerCostBox
