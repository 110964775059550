import { SVGProps, memo } from 'react'

const ChevronDownIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeWidth = 2,
	viewBox = '0 0 14 9',
	xmlns = 'http://www.w3.org/2000/svg',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			{...props}
		>
			<path d="M1 1.55682L7 7.21067L13 1.55682" />
		</svg>
	)
}

const MemoisedChevronDownIcon = memo(ChevronDownIcon)

export default MemoisedChevronDownIcon
