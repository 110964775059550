import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { OmniCountryCode } from 'src/_shared/enums/omni'

import { PlaceSuggestion } from '../types/google'

const ROOT_GOOGLE_QUERY_KEY = 'Google'

enum GoogleQueryKey {
	PlaceSuggestions = 'PlaceSuggestions',
	ReverseGeocoding = 'ReverseGeocoding'
}

interface PlaceSuggestionsQueryParams {
	searchInputValue: string
}

export const usePlaceSuggestionsQuery = <TData = PlaceSuggestion[]>(
	params: PlaceSuggestionsQueryParams,
	options?: Omit<
		UseQueryOptions<
			PlaceSuggestion[],
			Error,
			TData,
			[string, GoogleQueryKey.PlaceSuggestions, PlaceSuggestionsQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_GOOGLE_QUERY_KEY, GoogleQueryKey.PlaceSuggestions, params],
		queryFn: async (): Promise<PlaceSuggestion[]> => {
			const { AutocompleteService, PlacesService } = (await google.maps.importLibrary(
				'places'
			)) as google.maps.PlacesLibrary

			const dummyDivElement = document.createElement('div')
			dummyDivElement.id = 'google-maps-dummy-element' // TODO: To investigate whether this will cause issues

			const autoCompleteService = new AutocompleteService()
			const placesService = new PlacesService(dummyDivElement)

			// Step 1: Get Place Predictions via the library
			const data = await autoCompleteService.getPlacePredictions({
				input: params.searchInputValue,
				region: 'SG' // TODO: Make this dynamic
			})

			// Step 2: Get geometry-related information for each Place Prediction
			const predictions = data.predictions

			if (predictions.length > 0) {
				const results = await Promise.all(
					predictions.map(async (prediction): Promise<PlaceSuggestion | null> => {
						return new Promise((resolve, reject) => {
							try {
								placesService.getDetails(
									{
										placeId: prediction.place_id,
										fields: ['geometry']
									},
									(placeResult) => {
										if (placeResult !== null) {
											const latitude = placeResult.geometry?.location?.lat()
											const longitude = placeResult.geometry?.location?.lng()

											if (latitude !== undefined && longitude !== undefined) {
												const placeSuggestion: PlaceSuggestion = {
													...prediction,
													coordinates: {
														latitude,
														longitude
													}
												}

												resolve(placeSuggestion)
											}
										} else {
											resolve(null)
										}
									}
								)
							} catch (error) {
								reject(error as Error)
							}
						})
					})
				)

				// filter those predictions that do not have coordinates
				return results.filter((result): result is PlaceSuggestion => result !== null)
			}

			return []
		}
	})
}

interface ReverseGeocodingQueryParams {
	latitude: number
	longitude: number
}

export const useReverseGeocodingQuery = <TData = OmniCountryCode>(
	params: ReverseGeocodingQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniCountryCode,
			Error,
			TData,
			[string, GoogleQueryKey.ReverseGeocoding, ReverseGeocodingQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_GOOGLE_QUERY_KEY, GoogleQueryKey.ReverseGeocoding, params],
		queryFn: async (): Promise<OmniCountryCode> => {
			const { Geocoder } = (await google.maps.importLibrary(
				'geocoding'
			)) as google.maps.GeocodingLibrary

			const geocoder = new Geocoder()

			try {
				const geocodeResult = await geocoder.geocode({
					location: {
						lat: params.latitude,
						lng: params.longitude
					}
				})

				const results = geocodeResult.results

				// Search for the country component in the address
				for (const result of results) {
					for (const component of result.address_components) {
						if (component.types.includes('country')) {
							switch (component.long_name) {
								case 'Singapore':
									return OmniCountryCode.Singapore

								case 'Malaysia':
									return OmniCountryCode.Malaysia

								case 'Thailand':
									return OmniCountryCode.Thailand
							}
						}
					}
				}

				throw new Error('Country not found')
			} catch (error) {
				return Promise.reject(error as Error)
			}
		}
	})
}
