import { MouseEvent, useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import LocationListItem from 'src/_shared/components/LocationListItem'
import BookmarkEmptyIcon from 'src/_shared/components/_icons/BookmarkEmptyIcon'
import CheckRadiusFilledIcon from 'src/_shared/components/_icons/CheckRadiusFilledIcon'
import CheckRadiusIcon from 'src/_shared/components/_icons/CheckRadiusIcon'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni/location'

interface FavouriteLocationsListControllerProps {
	locations: OmniLocationNearby[]
	toggledLocations: string[]
	isEditing: boolean
	onFetchNextPageCallback?: () => void
	onToggleLocation: (locationUid: string) => void
}

const BOTTOM_THRESHOLD = 450 // Pixels

const FavouriteLocationListController = ({
	locations,
	toggledLocations,
	isEditing,
	onFetchNextPageCallback: handleFetchNextPageCallback,
	onToggleLocation: handleToggleLocation
}: FavouriteLocationsListControllerProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const renderRow = useCallback(
		(currentIndex: number): JSX.Element => {
			const handleLocationListItemClick = (location: OmniLocation): void => {
				if (location.uid && !isEditing) {
					navigate([ScreenRoutePath.Location, location.uid])
				}
			}

			const locationUid = locations[currentIndex].location?.uid

			const isLocationChecked = toggledLocations.some((uid): boolean => uid === locationUid)

			const onToggleLocation = (event: MouseEvent): void => {
				event.stopPropagation()
				handleToggleLocation(locationUid ?? '')
			}

			return (
				<LocationListItem
					locationNearby={locations[currentIndex]}
					key={currentIndex}
					onLocationListItemClick={handleLocationListItemClick}
					startAdornment={
						isEditing ? (
							<div onClick={onToggleLocation} className="pr-4">
								{isLocationChecked ? (
									<CheckRadiusFilledIcon className="h-5 w-5 text-error-300" />
								) : (
									<CheckRadiusIcon className="h-5 w-5 text-typography-primary" />
								)}
							</div>
						) : null
					}
				/>
			)
		},
		[toggledLocations, locations, isEditing, navigate, handleToggleLocation]
	)

	return (
		<div className="w-full">
			{locations.length === 0 ? (
				<div className="flex h-full flex-1 flex-col items-center justify-center space-y-8">
					<BookmarkEmptyIcon className="h-28 w-28 text-grayscale-500" />

					<div className="flex flex-col space-y-1 text-center">
						<p className="body-1-bold text-grayscale-800">No favorite charger location found</p>

						<p className="body-1-normal text-grayscale-700">
							Please find a location and make it a favorite
						</p>
					</div>
				</div>
			) : (
				<Virtuoso
					atBottomThreshold={BOTTOM_THRESHOLD}
					data={locations}
					endReached={handleFetchNextPageCallback}
					itemContent={renderRow}
					components={{ Footer: (): JSX.Element => <div className="h-12" /> }}
				/>
			)}
		</div>
	)
}

export default FavouriteLocationListController
