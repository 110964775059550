import { useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import LocationListItem from 'src/_shared/components/LocationListItem'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni/location'

interface LocationsListControllerProps {
	locations: OmniLocationNearby[]
	onFetchNextPageCallback?: () => void
}

const BOTTOM_THRESHOLD = 450 // Pixels

const LocationListController = ({
	locations,
	onFetchNextPageCallback: handleFetchNextPageCallback
}: LocationsListControllerProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const renderRow = useCallback(
		(index: number): JSX.Element => {
			const handleLocationListItemClick = (location: OmniLocation): void => {
				if (location.uid) {
					navigate([ScreenRoutePath.Location, location.uid])
				}
			}

			return (
				<LocationListItem
					locationNearby={locations[index]}
					key={index}
					onLocationListItemClick={handleLocationListItemClick}
				/>
			)
		},
		[locations, navigate]
	)

	return (
		<div className="w-full">
			<Virtuoso
				atBottomThreshold={BOTTOM_THRESHOLD}
				data={locations}
				endReached={handleFetchNextPageCallback}
				itemContent={renderRow}
				components={{ Footer: (): JSX.Element => <div className="h-12" /> }}
			/>
		</div>
	)
}

export default LocationListController
