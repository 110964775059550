import { SVGProps, memo } from 'react'

const BookmarkEmptyIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 111 111',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				opacity="0.4"
				d="M74 41.5788V94.1188C74 100.825 69.19 103.646 63.3162 100.409L45.14 90.28C43.1975 89.2162 40.0525 89.2162 38.11 90.28L19.9338 100.409C14.06 103.646 9.25 100.825 9.25 94.1188V41.5788C9.25 33.67 15.725 27.1951 23.6338 27.1951H59.6163C67.525 27.1951 74 33.67 74 41.5788Z"
			/>
			<path d="M46.5274 55.4999L50.5974 51.4299C51.9387 50.0887 51.9387 47.8687 50.5974 46.5274C49.2562 45.1862 47.0362 45.1862 45.6949 46.5274L41.6249 50.5974L37.5549 46.5274C36.2137 45.1862 33.9937 45.1862 32.6524 46.5274C31.3112 47.8687 31.3112 50.0887 32.6524 51.4299L36.7224 55.4999L32.6524 59.57C31.3112 60.9112 31.3112 63.1312 32.6524 64.4724C33.3462 65.1662 34.2249 65.4899 35.1037 65.4899C35.9824 65.4899 36.8612 65.1662 37.5549 64.4724L41.6249 60.4024L45.6949 64.4724C46.3887 65.1662 47.2674 65.4899 48.1462 65.4899C49.0249 65.4899 49.9037 65.1662 50.5974 64.4724C51.9387 63.1312 51.9387 60.9112 50.5974 59.57L46.5274 55.4999Z" />
			<path d="M101.75 23.6337V76.1738C101.75 82.88 96.94 85.7012 91.0663 82.4637L74 72.9362V41.5787C74 33.67 67.525 27.195 59.6163 27.195H37V23.6337C37 15.7249 43.475 9.25 51.3837 9.25H87.3662C95.275 9.25 101.75 15.7249 101.75 23.6337Z" />
		</svg>
	)
}

const MemoisedBookmarkEmptyIcon = memo(BookmarkEmptyIcon)

export default MemoisedBookmarkEmptyIcon
