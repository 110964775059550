import { memo } from 'react'

import { GoogleMapMarkerProps } from '../types'

type CurrentPositionMarkerProps = GoogleMapMarkerProps

const CurrentPositionMarker = ({ lat, lng }: CurrentPositionMarkerProps): JSX.Element => {
	return (
		// Transformation done to reflect the true center of the coordinates
		<div
			id={`current-position-${[lat, lng].join(',')}`}
			className="pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 transform"
		>
			{/* Outermost Radial Ring */}
			<div className="flex h-14 w-14 items-center justify-center rounded-full bg-[#4285F4] bg-opacity-10">
				{/* Inner Ring */}
				<div className="flex h-6 w-6 items-center justify-center rounded-full bg-white">
					<div className="animate-scale h-4 w-4 rounded-full bg-[#4285F4]" />
				</div>
			</div>
		</div>
	)
}

const MemoisedCurrentPositionMarker = memo(CurrentPositionMarker)

export default MemoisedCurrentPositionMarker
