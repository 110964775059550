import GoogleMap, { UseScriptStatus } from 'google-maps-react-markers'
import { memo, PropsWithChildren, useEffect, useState } from 'react'
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_MAP_ID } from 'src/_shared/constants/env'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import { classNames } from 'src/_shared/utils/elements'

import { DEFAULT_CENTER, MAX_ZOOM } from '../constants'
import { GoogleApiProps, GoogleMapOptions } from '../types'

type MapProps = PropsWithChildren<{
	className?: string
	defaultCenter?: google.maps.LatLngLiteral | null
	defaultZoom?: number | null
	onChange?: (options: GoogleMapOptions) => void
	onLoaded?: (props: GoogleApiProps) => void
}>

const Map = ({
	className,
	children,
	defaultCenter,
	defaultZoom,
	onChange: handleChange,
	onLoaded: handleLoaded
}: MapProps): JSX.Element => {
	const [mapStatus, setMapStatus] = useState<UseScriptStatus>('idle')

	/**
	 * Manually load the Google Maps API with the `maps` library.
	 */
	useEffect((): void => {
		const loadGoogleMap = async (): Promise<void> => {
			try {
				await google.maps.importLibrary('maps')
				setMapStatus('ready')
			} catch (error) {
				console.error('[Map]', error)
				setMapStatus('error')
			}
		}
		setMapStatus('loading')
		void loadGoogleMap()
	}, [])

	return (
		<GoogleMap
			apiKey={GOOGLE_MAPS_API_KEY}
			containerProps={{
				className: classNames('flex-grow', className)
			}}
			defaultCenter={defaultCenter ?? DEFAULT_CENTER}
			defaultZoom={defaultZoom ?? DEFAULT_ZOOM}
			loadScriptExternally={true}
			status={mapStatus}
			onChange={handleChange}
			onGoogleApiLoaded={handleLoaded}
			options={{
				clickableIcons: false,
				disableDefaultUI: true,
				gestureHandling: 'greedy',
				keyboardShortcuts: false,
				mapId: GOOGLE_MAPS_MAP_ID,
				mapTypeId: 'roadmap',
				maxZoom: MAX_ZOOM,
				styles: [
					{
						featureType: 'poi.business',
						elementType: 'labels',
						stylers: [{ visibility: 'off' }]
					}
				]
			}}
		>
			{children}
		</GoogleMap>
	)
}

const MemoisedMap = memo(Map)

export default MemoisedMap
