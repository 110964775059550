import { memo, MouseEvent } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface IconButtonProps {
	className?: string
	children: JSX.Element
	onClick: (event: MouseEvent) => void
}

const IconButton = ({
	className,
	children,
	onClick: handleClick
}: IconButtonProps): JSX.Element => {
	return (
		<button
			onClick={handleClick}
			className={classNames('flex h-10 w-10 items-center justify-center rounded-full', className)}
		>
			{children}
		</button>
	)
}

const MemoisedIconButton = memo(IconButton)

export default MemoisedIconButton
