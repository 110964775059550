import { memo } from 'react'
import ReactSlider, { ReactSliderProps } from 'react-slider'
import { formatDataTestId } from 'src/_shared/utils/string'

import './slideRange.css'

type SlideRangeProps = ReactSliderProps<[number, number]> & {
	dataTestIdPrefix?: string
}

const SlideRange = (props: SlideRangeProps): JSX.Element => {
	const { value, min, max, minDistance, onChange: handleChange, dataTestIdPrefix = '' } = props
	return (
		<ReactSlider<[number, number]>
			data-testid={formatDataTestId([dataTestIdPrefix, 'slide-range'])}
			className="horizontal-slider"
			thumbClassName="thumb"
			trackClassName="track"
			value={value}
			min={min}
			max={max}
			minDistance={minDistance}
			onChange={handleChange}
		/>
	)
}

const MemoisedSlideRange = memo(SlideRange)

export default MemoisedSlideRange
