import { useCallback, useMemo } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'

const USER_ZOOM_COORDINATES_KEY = 'userZoomCoordinates'

interface UserZoomCoordinates {
	zoomCoordinates: {
		lng: string
		lat: string
	} | null
	zoom: string | null
}

const DEFAULT_USER_COORDINATES: UserZoomCoordinates = {
	zoomCoordinates: null,
	zoom: null
}

export const useUserZoomCoordinates = (): {
	userZoomCoordinates: UserZoomCoordinates | null
	setUserZoomCoordinate: <T extends keyof UserZoomCoordinates>(
		key: T,
		value: UserZoomCoordinates[T]
	) => void
	setUserZoomCoordinates: (userZoomCoordinates: UserZoomCoordinates | null) => void
} => {
	const [userZoomCoordinates, setUserZoomCoordinates] =
		useSessionStorage<UserZoomCoordinates>(USER_ZOOM_COORDINATES_KEY)

	const currentUserCoordinates = useMemo((): UserZoomCoordinates | null => {
		if (!userZoomCoordinates) {
			return null
		}
		return { ...DEFAULT_USER_COORDINATES, ...userZoomCoordinates }
	}, [userZoomCoordinates])

	const setUserZoomCoordinatesCallback = useCallback(
		(value: UserZoomCoordinates | null) => {
			setUserZoomCoordinates(value)
		},
		[setUserZoomCoordinates]
	)

	const setUserZoomCoordinate = useCallback(
		<T extends keyof UserZoomCoordinates>(key: T, value: UserZoomCoordinates[T]) => {
			if (currentUserCoordinates === null) {
				setUserZoomCoordinates({
					...DEFAULT_USER_COORDINATES,
					[key]: value
				})

				return
			}

			const newUserCoordinates: UserZoomCoordinates = {
				...currentUserCoordinates,
				[key]: value // lastly, update the supplied key with the new value
			}

			setUserZoomCoordinates(newUserCoordinates)
		},
		[currentUserCoordinates, setUserZoomCoordinates]
	)

	return {
		userZoomCoordinates: currentUserCoordinates,
		setUserZoomCoordinates: setUserZoomCoordinatesCallback,
		setUserZoomCoordinate
	}
}
