import { SVGProps, memo } from 'react'

const BookmarkIcon = ({
	fill = 'none',
	viewBox = '0 0 14 19',
	xmlns = 'http://www.w3.org/2000/svg',
	stroke = 'currentColor',
	strokeWidth = '1.5',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			stroke={stroke}
			strokeWidth={strokeWidth}
			{...props}
		>
			<path d="M1.85171 2.03533L1.85172 2.03531C2.19447 1.69256 2.65934 1.5 3.14408 1.5H10.8559C11.3406 1.5 11.8055 1.69256 12.1483 2.0353L12.1483 2.03537C12.4911 2.37809 12.6836 2.84292 12.6836 3.32768V17.2903L7.57203 13.7256L7.00002 13.3267L6.42801 13.7256L1.3164 17.2903V3.32768C1.3164 2.84294 1.50895 2.37807 1.85171 2.03533Z" />
		</svg>
	)
}

const MemoisedBookmarkIcon = memo(BookmarkIcon)

export default MemoisedBookmarkIcon
