import { HTMLAttributes, ReactNode, useCallback, useMemo } from 'react'
import { ConnectorPowerType, FilterType, LocationFilterModalType } from 'src/_shared/enums/filter'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'
import { classNames } from 'src/_shared/utils/elements'

import BatteryFilledIcon from '../_icons/BatteryFilledIcon'
import ChevronDownIcon from '../_icons/ChevronDownIcon'

const QUICK_FILTER_OPTIONS: {
	key: FilterType
	label: string
	icon: ReactNode
}[] = [
	{
		key: FilterType.All,
		label: 'All',
		icon: null
	},
	{
		key: FilterType.Cpo,
		label: 'Charge Point Operators',
		icon: <ChevronDownIcon className="mr-1.5 mt-0.5 w-2" />
	},
	{
		key: FilterType.Ac,
		label: ConnectorPowerType.Ac,
		icon: <BatteryFilledIcon className="mr-0.5 w-3.5" />
	},
	{
		key: FilterType.Dc,
		label: ConnectorPowerType.Dc,
		icon: <BatteryFilledIcon className="mr-0.5 w-3.5" />
	}
]

interface QuickFilterButtonProps {
	icon: ReactNode
	isActive: boolean
	filterType: FilterType
	label: string
	onClick: () => void
}

const QuickFilterButton = ({
	label,
	icon,
	isActive,
	onClick: handleClick
}: QuickFilterButtonProps): JSX.Element => {
	return (
		<button
			className={classNames(
				'btn h-7 min-w-10 rounded-full bg-opacity-100 px-2.5 drop-shadow-md',
				isActive
					? 'primary text-button-primary-content'
					: 'bg-grayscale-100 text-typography-primary'
			)}
			onClick={handleClick}
		>
			{icon}
			<p className="caption-3-normal">{label}</p>
		</button>
	)
}

export type QuickFilterButtonsProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>

const QuickFilterButtons = ({ className, ...props }: QuickFilterButtonsProps): JSX.Element => {
	const { locationFilters, setFilter, setFilters } = useLocationFilters()

	const { showModal } = useLocationFiltersModalContext()

	const handleCpoFilterOnClick = useCallback((): void => {
		showModal(LocationFilterModalType.Cpo)
	}, [showModal])

	const handleSelectFilterOption = useCallback(
		(filterOption: FilterType): void => {
			if (filterOption === FilterType.Ac || filterOption === FilterType.Dc) {
				const newPowerType =
					filterOption === FilterType.Ac ? ConnectorPowerType.Ac : ConnectorPowerType.Dc

				if (locationFilters.powerType === newPowerType) {
					setFilter('powerType', null)
				} else {
					setFilter('powerType', newPowerType)
				}
			} else if (filterOption === FilterType.All) {
				setFilters(null)
			} else {
				// Else, CPO filter is handled separately in the FilterModal
				handleCpoFilterOnClick()
			}
		},
		[handleCpoFilterOnClick, locationFilters, setFilter, setFilters]
	)

	const isFilterOptionActiveMap = useMemo((): Record<FilterType, boolean> => {
		return {
			[FilterType.Ac]: locationFilters.powerType === ConnectorPowerType.Ac,
			[FilterType.Dc]: locationFilters.powerType === ConnectorPowerType.Dc,
			[FilterType.Cpo]:
				locationFilters.cpoEntityCodes !== null && locationFilters.cpoEntityCodes.length > 0,
			[FilterType.All]:
				locationFilters.powerType === null && locationFilters.cpoEntityCodes === null
		}
	}, [locationFilters.cpoEntityCodes, locationFilters.powerType])

	return (
		<div className={classNames('flex items-center justify-between', className)} {...props}>
			{QUICK_FILTER_OPTIONS.map(
				(option): JSX.Element => (
					<QuickFilterButton
						{...option}
						key={option.key}
						filterType={option.key}
						onClick={(): void => {
							handleSelectFilterOption(option.key)
						}}
						isActive={isFilterOptionActiveMap[option.key]}
					/>
				)
			)}
		</div>
	)
}

export default QuickFilterButtons
