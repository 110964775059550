import { keepPreviousData } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import useLanguage from 'src/_shared/hooks/useLanguage'
import { useStrapiTranslationsQuery } from 'src/_shared/queries/strapi'

/**
 * Retrieves translations data from Strapi using the provided `lang` query parameter.
 * If the translations data is incomplete, then affected instances of `formatMessage`
 * and `FormattedMessage` should fallback to the English `defaultMessage` value.
 */
const StrapiTranslationsWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const { language: locale } = useLanguage()

	const { data: translations, isLoading: isStrapiTranslationsLoading } = useStrapiTranslationsQuery(
		{
			locale
		},
		{
			staleTime: Infinity,
			placeholderData: keepPreviousData,
			select: (translationsData): Record<string, string> => {
				return translationsData.attributes.translations ?? {}
			}
		}
	)

	if (isStrapiTranslationsLoading) {
		return null
	}
	return (
		<IntlProvider locale={locale} messages={translations}>
			{children}
		</IntlProvider>
	)
}

export default StrapiTranslationsWrapper
