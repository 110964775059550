import { SVGProps, memo } from 'react'

const CloseCircleIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeWidth = '1.5',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	viewBox = '0 0 20 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path d="M10.0001 18.3337C14.5834 18.3337 18.3334 14.5837 18.3334 10.0003C18.3334 5.41699 14.5834 1.66699 10.0001 1.66699C5.41675 1.66699 1.66675 5.41699 1.66675 10.0003C1.66675 14.5837 5.41675 18.3337 10.0001 18.3337Z" />
			<path d="M7.6416 12.3583L12.3583 7.6416" />
			<path d="M12.3583 12.3583L7.6416 7.6416" />
		</svg>
	)
}

const MemoisedCloseCircleIcon = memo(CloseCircleIcon)

export default MemoisedCloseCircleIcon
