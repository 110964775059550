import { useCallback, useEffect, useMemo } from 'react'

import { LANGUAGE_KEY } from '../constants/storage'
import { Language } from '../enums/env'
import { isValidLanguage } from '../utils/string'
import { useLocalStorageItem } from './useStorageItem'

/**
 * Returns the currently set `lang`.
 */
const useLanguage = (): {
	language: Language
	setLanguage: (lang: Language) => void
} => {
	const [language, setLanguage] = useLocalStorageItem(LANGUAGE_KEY)

	const url = useMemo(() => new URL(window.location.href), [])
	const languageQueryParameter = url.searchParams.get(LANGUAGE_KEY)

	/**
	 * If a valid `lang` query parameter is provided,
	 * replace the current value found in `localStorage`.
	 */
	useEffect((): void => {
		if (isValidLanguage(languageQueryParameter)) {
			setLanguage(languageQueryParameter)
		}

		if (languageQueryParameter !== null) {
			// Remove the query parameter from the URL once processed
			url.searchParams.delete(LANGUAGE_KEY)
			window.history.replaceState(null, '', url.toString())
		}
	}, [languageQueryParameter, setLanguage, url])

	const handleSetLanguage = useCallback(
		(lang: Language): void => {
			if (isValidLanguage(lang)) {
				setLanguage(lang)
			}
		},
		[setLanguage]
	)

	const formattedLanguage = useMemo(() => {
		if (isValidLanguage(languageQueryParameter)) {
			return languageQueryParameter
		} else if (isValidLanguage(language)) {
			return language
		}
		return Language.English
	}, [language, languageQueryParameter])

	return {
		language: formattedLanguage,
		setLanguage: handleSetLanguage
	}
}

export default useLanguage
