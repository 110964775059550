import { CSSProperties, memo, ReactNode } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface ChargerTagProps {
	value: ReactNode
	/**
	 * Additional classes to be added to `ChargerTag`.
	 */
	className?: string
	/**
	 * Default styling for `ChargerTag` can be overriden with the `style` prop.
	 */
	style?: CSSProperties
	'data-testid'?: string
}

const ChargerTag = ({
	value,
	className = '',
	style,
	'data-testid': dataTestId
}: ChargerTagProps): JSX.Element => {
	return (
		<span
			data-testid={dataTestId}
			className={classNames(
				'caption-1-semibold rounded-xl bg-grayscale-900 px-1.5 py-0.5',
				className
			)}
			style={style}
		>
			{value}
		</span>
	)
}

const MemoisedChargerTag = memo(ChargerTag)

export default MemoisedChargerTag
