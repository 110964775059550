import { SVGProps, memo } from 'react'

const ListIcon = ({
	fill = 'none',
	viewBox = '0 0 25 25',
	xmlns = 'http://www.w3.org/2000/svg',
	stroke = 'currentColor',
	strokeWidth = '2.5',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			stroke={stroke}
			strokeWidth={strokeWidth}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			{...props}
		>
			<path d="M8.5 6.5H21.5M8.5 12.5H21.5M8.5 18.5H21.5M3.5 6.5H3.51M3.5 12.5H3.51M3.5 18.5H3.51" />
		</svg>
	)
}

const MemoisedListIcon = memo(ListIcon)

export default MemoisedListIcon
