import { SVGProps, memo } from 'react'

const FilterIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 23 24',
	strokeLinecap = 'round',
	strokeWidth = '1.5',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			stroke={stroke}
			strokeWidth={strokeWidth}
			strokeLinecap={strokeLinecap}
			{...props}
		>
			<path d="M2.875 7.20837H20.125" />
			<path d="M5.75 12H17.25" />
			<path d="M9.58325 16.7916H13.4166" />
		</svg>
	)
}

const MemoisedFilterIcon = memo(FilterIcon)

export default MemoisedFilterIcon
