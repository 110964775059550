import { useCallback, useState } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import ChevronDownIcon from 'src/_shared/components/_icons/ChevronDownIcon'
import ChevronUpIcon from 'src/_shared/components/_icons/ChevronUpIcon'
import { ConnectorPowerType } from 'src/_shared/enums/filter'
import { ConnectorWithDetails } from 'src/_shared/types/charging'
import { getAvailableConnectorsCount } from 'src/_shared/utils/charging'
import { classNames } from 'src/_shared/utils/elements'

import ConnectorsListItem from './ConnectorsListItem'

interface ConnectorsListViewProps {
	className?: string
	powerType: ConnectorPowerType
	power: string
	connectors: ConnectorWithDetails[]
	cpoEntityCode: string
	locationUid?: string
	initialIsCollapsed: boolean
}

const ConnectorsListView = ({
	className,
	powerType,
	power,
	connectors,
	cpoEntityCode,
	locationUid = '',
	initialIsCollapsed
}: ConnectorsListViewProps): JSX.Element => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(initialIsCollapsed)

	const navigate = useRouterNavigate()

	const { availableCount, totalCount } = getAvailableConnectorsCount(connectors)

	const handleToggleIsCollapsedClick = (): void => {
		setIsCollapsed((prev) => !prev)
	}

	const handleClickConnector = useCallback(
		({ connector, evse }: ConnectorWithDetails): void => {
			const priceModifiers = connector.active_tariff?.price_modifiers ?? []

			// GUARD CONDITION: against random uids of evses and connectors
			if (evse.uid && connector.uid) {
				navigate([ScreenRoutePath.Charger, cpoEntityCode, locationUid, evse.uid, connector.uid], {
					state: priceModifiers
				})
			} else {
				navigate(ScreenRoutePath.Map)
			}
		},
		[cpoEntityCode, locationUid, navigate]
	)

	return (
		<div className={classNames('w-full rounded-lg bg-primary-800/10 px-4 py-3', className)}>
			<button
				className="flex w-full flex-row items-center justify-between"
				name={isCollapsed ? 'expand' : 'collapse'}
				onClick={handleToggleIsCollapsedClick}
			>
				<div className="flex flex-row items-center space-x-1">
					<span className="body-2-semibold mr-2 text-typography-primary">{powerType}</span>
					<span className="body-2-normal text-typography-primary">
						<span className={classNames(availableCount === 0 ? 'text-error-300' : null)}>
							{availableCount}
						</span>
						/{totalCount}
					</span>
				</div>
				<div className="flex flex-row items-center space-x-2">
					<div className="body-2-semibold text-typography-primary">{power} kW</div>
					{!isCollapsed ? (
						<ChevronUpIcon className="mt-0.5 w-3 text-primary-800" />
					) : (
						<ChevronDownIcon className="mt-0.5 w-3 text-primary-800" />
					)}
				</div>
			</button>
			{!isCollapsed && (
				<div className="mt-4 flex-row">
					{connectors.map(
						(connector): JSX.Element => (
							<ConnectorsListItem
								onClick={handleClickConnector}
								connector={connector}
								key={connector.connector.uid}
								cpoEntityCode={cpoEntityCode}
							/>
						)
					)}
				</div>
			)}
		</div>
	)
}

export default ConnectorsListView
