import { memo, useCallback, useMemo, useState } from 'react'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import SlideRange from 'src/_shared/components/SlideRange'
import CloseIcon from 'src/_shared/components/_icons/CloseIcon'
import { DISABLE_SUBSCRIPTION } from 'src/_shared/constants/env'
import {
	ConnectorPowerType,
	LocationFilterModalType,
	SubscriptionStatus
} from 'src/_shared/enums/filter'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useLocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'
import { formatDistanceDisplay } from 'src/_shared/utils/format'

interface LocationFilterModalProps {
	open: boolean
	filterModalType: LocationFilterModalType | null
}

const IS_SUBSCRIPTION_ENABLED = !DISABLE_SUBSCRIPTION

const MIN_DISTANCE = 0 // 0 km

const MAX_DISTANCE = 50000 // 50 km

const DEFAULT_DISTANCE_RANGE: [number, number] = [MIN_DISTANCE, MAX_DISTANCE]

/**
 * Colours for Filter Button
 */
const SELECTED_COLOUR = 'border-primary-800 bg-primary-800 text-grayscale-100'

/**
 * Colours for Filter Button
 */
const UNSELECTED_COLOUR = 'border-divider-primary bg-white text-typography-primary'

const LocationFilterModal = ({ open, filterModalType }: LocationFilterModalProps): JSX.Element => {
	const { hideModal } = useLocationFiltersModalContext()

	const { locationFilters, setFilters } = useLocationFilters()

	const [selectedCpos, setSelectedCpos] = useState<string[] | null>(locationFilters.cpoEntityCodes)

	const [selectedPowerType, setSelectedPowerType] = useState<ConnectorPowerType | null>(
		locationFilters.powerType
	)

	const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | null>(
		locationFilters.subscriptionStatus
	)

	const [distanceRange, setDistanceRange] = useState<[number, number] | null>(
		locationFilters.distanceRange
	)

	const { brandData } = useStrapiContext()

	const { isAuthenticated } = useAuthContext()

	const cpoOptionsList = useMemo(() => {
		return brandData?.attributes.configuration?.cpoOptions ?? []
	}, [brandData?.attributes.configuration?.cpoOptions])

	const handleClearFilters = (): void => {
		setSelectedCpos(null)
		setSelectedPowerType(null)
		setSubscriptionStatus(null)
		setDistanceRange(null)
	}

	const handleSelectCpoChange = (cpo: string): void => {
		setSelectedCpos((prev): string[] | null => {
			if (prev === null) {
				return [cpo]
			}
			// if the cpo is already selected, remove it
			if (prev.includes(cpo)) {
				const filtered = prev.filter((selectedCpo): boolean => selectedCpo !== cpo)
				return filtered.length === 0 ? null : filtered
			}
			// else add it
			return [...prev, cpo]
		})
	}

	const handleSubscribedFilterChange = (subscriptionStatus: SubscriptionStatus): void => {
		setSubscriptionStatus((prev): SubscriptionStatus | null => {
			if (prev === subscriptionStatus) {
				return null
			}
			return subscriptionStatus
		})
	}

	const handlePowerTypeFilterChange = (powerType: ConnectorPowerType) => {
		setSelectedPowerType((prev): ConnectorPowerType | null => {
			if (prev === powerType) {
				return null
			}
			return powerType
		})
	}

	const handleClose = useCallback((): void => {
		hideModal()
	}, [hideModal])

	const handleApplyFilters = (): void => {
		setFilters({
			cpoEntityCodes: selectedCpos,
			distanceRange,
			powerType: selectedPowerType,
			subscriptionStatus
		})
		handleClose()
	}

	const handleDistanceRangeChange = (distanceRange: [number, number]): void => {
		setDistanceRange(distanceRange)
	}

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalCard className="relative">
				<button className="absolute right-6 top-6" onClick={handleClose}>
					<CloseIcon className="h-4 w-4 text-grayscale-600" />
				</button>
				<div className="flex max-h-[70vh] flex-col space-y-5 px-1">
					<div className="flex w-full items-center justify-between">
						<h1 className="text-typography-primary">Filter</h1>
					</div>
					<div className="w-full space-y-5 overflow-scroll">
						{/* Charge Point Operators */}
						<div className="flex w-full flex-col space-y-1">
							<p className="body-1-medium text-typography-secondary">Charge Point Operator</p>
							<div className="flex w-full flex-row flex-wrap justify-start pt-1">
								{cpoOptionsList.map(
									(cpo): JSX.Element => (
										<button
											key={cpo.entityCode}
											className={classNames(
												'mb-2 mr-2 rounded-md border px-2 py-0.5',
												selectedCpos !== null && selectedCpos.includes(cpo.entityCode ?? '')
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={(): void => {
												handleSelectCpoChange(cpo.entityCode ?? '')
											}}
										>
											<p className="body-1-medium">{cpo.name ?? '-'}</p>
										</button>
									)
								)}
							</div>
						</div>
						{filterModalType === LocationFilterModalType.All && (
							<>
								{/* Subscription */}
								{isAuthenticated && IS_SUBSCRIPTION_ENABLED && (
									<div className="flex flex-col space-y-1">
										<p className="body-1-medium text-typography-secondary">User Type</p>
										<div className="flex flex-row justify-start space-x-2 pt-1">
											<button
												className={classNames(
													'rounded-md border px-2 py-0.5',
													subscriptionStatus === SubscriptionStatus.Subscribed
														? SELECTED_COLOUR
														: UNSELECTED_COLOUR
												)}
												onClick={(): void => {
													handleSubscribedFilterChange(SubscriptionStatus.Subscribed)
												}}
											>
												<p className="body-1-medium">Subscribed</p>
											</button>
											<button
												className={classNames(
													'rounded-md border px-2 py-0.5',
													subscriptionStatus === SubscriptionStatus.NotSubscribed
														? SELECTED_COLOUR
														: UNSELECTED_COLOUR
												)}
												onClick={(): void => {
													handleSubscribedFilterChange(SubscriptionStatus.NotSubscribed)
												}}
											>
												<p className="body-1-medium">Non-Subscribed</p>
											</button>
										</div>
									</div>
								)}
								{/* Power Type */}
								<div className="mb-3 flex w-full flex-col space-y-1 pb-2 pt-3">
									<p className="body-1-medium text-typography-secondary">Power Type</p>
									<div className="flex w-full flex-row justify-start space-x-2 pt-1">
										<button
											className={classNames(
												'rounded-md border px-2 py-0.5',
												selectedPowerType === ConnectorPowerType.Ac
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={(): void => {
												handlePowerTypeFilterChange(ConnectorPowerType.Ac)
											}}
										>
											<p className="body-1-medium">AC Only</p>
										</button>
										<button
											className={classNames(
												'rounded-md border px-2 py-0.5',
												selectedPowerType === ConnectorPowerType.Dc
													? SELECTED_COLOUR
													: UNSELECTED_COLOUR
											)}
											onClick={(): void => {
												handlePowerTypeFilterChange(ConnectorPowerType.Dc)
											}}
										>
											<p className="body-1-medium">DC Only</p>
										</button>
									</div>
								</div>
								{/* Distance Filter */}
								<div className="flex w-full flex-col space-y-1 pb-2 pt-3">
									<p className="body-1-medium text-typography-secondary">Distance</p>
									<div className="flex w-full flex-col justify-center space-y-3">
										<div className="flex flex-row items-center justify-start space-x-2">
											<p className="body-1-medium text-typography-primary">
												{formatDistanceDisplay(
													distanceRange !== null ? distanceRange[0] : MIN_DISTANCE
												)}
											</p>
											<p className="body-1-medium text-typography-tertiary">•</p>
											<p className="body-1-medium text-typography-primary">
												{formatDistanceDisplay(
													distanceRange !== null ? distanceRange[1] : MAX_DISTANCE
												)}
											</p>
										</div>
										<SlideRange
											value={distanceRange ?? DEFAULT_DISTANCE_RANGE}
											min={MIN_DISTANCE}
											max={MAX_DISTANCE}
											minDistance={500}
											onChange={handleDistanceRangeChange}
										/>
									</div>
									<div className="flex w-full flex-row items-center justify-between pt-4">
										<p className="body-1-light text-typography-tertiary">
											{formatDistanceDisplay(MIN_DISTANCE)}
										</p>
										<p className="body-1-light text-typography-tertiary">
											{formatDistanceDisplay(MAX_DISTANCE)}
										</p>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="flex flex-row space-x-2">
						<Button
							data-testid="fm-btn-apply-filter"
							className="w-full"
							onClick={handleApplyFilters}
						>
							Apply
						</Button>

						<Button
							variant="secondary"
							data-testid="fm-btn-clear-filter"
							className="w-full"
							onClick={handleClearFilters}
						>
							Clear
						</Button>
					</div>
				</div>
			</ModalCard>
		</Modal>
	)
}

const MemoisedLocationFilterModal = memo(LocationFilterModal)

export default MemoisedLocationFilterModal
