import { memo, useMemo } from 'react'
import Select, { SelectOption, SelectProps } from 'src/_shared/components/Select'
import { OmniCountryCode } from 'src/_shared/enums/omni'
import { formatDataTestId } from 'src/_shared/utils/string'

type SelectFavouritesCountryProps<T> = {
	dataTestIdPrefix?: string
} & Omit<SelectProps<T>, 'options' | 'ref'>

const SelectFavouritesCountry = <T extends string | number>({
	dataTestIdPrefix = '',
	...props
}: SelectFavouritesCountryProps<T>): JSX.Element => {
	const options = useMemo((): SelectOption<T>[] | SelectOption<string>[] => {
		const countryOptions = Object.entries(OmniCountryCode).map(
			([key, value]): SelectOption<string> => {
				return {
					label: key,
					dropdownLabel: key,
					value
				}
			}
		)

		// Include "All countries" option
		return [{ label: 'All', dropdownLabel: 'All', value: '' }, ...countryOptions]
	}, [])

	return (
		<div className="flex h-12 w-full flex-row rounded-2xl border border-divider-primary px-4">
			<Select
				dataTestIdPrefix={formatDataTestId([dataTestIdPrefix, 'favourite-country'])}
				{...props}
				options={options}
				mainClassName="w-full"
			/>
		</div>
	)
}

const MemoisedSelectFavouritesCountry = memo(SelectFavouritesCountry)

export default MemoisedSelectFavouritesCountry
