import { SVGProps, memo } from 'react'

const CheckRadiusFilledIcon = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	viewBox = '0 0 20 21',
	strokeWidth = '1.5',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<rect x="0.5" y="0.939453" width="19" height="19" rx="5.5" />
			<path d="M5 11.4949L7.42726 13.9222L13.91 7.43945" />
			<path d="M5 11.4949L7.42726 13.9222L13.91 7.43945" stroke="white" />
		</svg>
	)
}

const MemoisedCheckRadiusFilledIcon = memo(CheckRadiusFilledIcon)

export default MemoisedCheckRadiusFilledIcon
