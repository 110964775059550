import { SVGProps, memo } from 'react'

const ParkingIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 16 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M13.52 2.78797C12.287 1.55107 10.56 0.779309 8.67456 0.780274H5.30317H4.98099H0.472717V19.7803H5.30313V14.4857H8.67452C10.56 14.4866 12.2865 13.7149 13.52 12.479C14.7569 11.2459 15.5282 9.51842 15.5272 7.63345C15.5282 5.74751 14.7569 4.021 13.52 2.78797ZM10.4463 9.40427C9.98589 9.8609 9.37318 10.1373 8.67456 10.1383H5.30317V5.12764H8.67456C9.37318 5.1286 9.98593 5.40499 10.4459 5.86162C10.903 6.32208 11.1789 6.93431 11.1798 7.63341C11.1789 8.33159 10.903 8.94389 10.4463 9.40427Z" />
		</svg>
	)
}

const MemoisedParkingIcon = memo(ParkingIcon)

export default MemoisedParkingIcon
