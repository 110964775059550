import { memo } from 'react'
import PriceModifierTag from 'src/_shared/components/PriceModifierTag'
import ChargerAcIcon from 'src/_shared/components/_icons/ChargerAcIcon'
import ChargerDcIcon from 'src/_shared/components/_icons/ChargerDcIcon'
import { ConnectorPowerType } from 'src/_shared/enums/filter'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useIsUserSubscribed } from 'src/_shared/hooks/useIsUserSubscribed'
import { ConnectorWithDetails } from 'src/_shared/types/charging'
import { OmniTariffPriceModifier } from 'src/_shared/types/omni'
import { getConnectorPowerType, isConnectorAvailable } from 'src/_shared/utils/charging'
import { classNames } from 'src/_shared/utils/elements'
import { getConnectorTariffPriceValue } from 'src/screens/LocationDetailsScreen/utils'

interface ConnectorsListItemProps {
	connector: ConnectorWithDetails
	cpoEntityCode: string
	onClick: (connector: ConnectorWithDetails) => void
}

const ConnectorsListItem = ({
	connector: connectorWithDetails,
	cpoEntityCode,
	onClick
}: ConnectorsListItemProps): JSX.Element => {
	const { isAuthenticated } = useAuthContext()

	const isUserSubscribed = useIsUserSubscribed(cpoEntityCode)

	const { connector, evse } = connectorWithDetails

	const powerType = getConnectorPowerType(connector)

	const isAvailable = isConnectorAvailable(connector)

	const priceModifiers = connector.active_tariff?.price_modifiers ?? []

	const priceModifiersApplied = priceModifiers.length !== 0

	const connectorName = `${connector.physical_reference ? `[${connector.physical_reference}] ` : ''}${evse.physical_reference ?? evse.evse_id}`

	return (
		<button
			className="w-full border-b border-primary-200 py-2 last:border-none"
			onClick={(): void => {
				onClick(connectorWithDetails)
			}}
		>
			<div className="flex w-full flex-row items-center space-x-5">
				<div className="flex flex-row items-center justify-start">
					<div
						className={classNames(
							'flex h-9 w-9 items-center justify-center rounded-full border-[3px]',
							isAvailable ? 'border-success-400' : 'border-error-300'
						)}
					>
						{powerType === ConnectorPowerType.Ac ? (
							<ChargerAcIcon className="w-6 text-typography-primary" />
						) : (
							<ChargerDcIcon className="w-4 text-typography-primary" />
						)}
					</div>
				</div>
				<div className="flex flex-grow">
					<p className="body-1-semibold break-words text-start text-typography-primary">
						{connectorName}
					</p>
				</div>
				<div className="flex flex-col items-end justify-center">
					<div className="flex items-start justify-end">
						{
							priceModifiersApplied &&
								isAuthenticated &&
								priceModifiers.map(
									(modifier: OmniTariffPriceModifier, index: number): JSX.Element => {
										return (
											<PriceModifierTag
												key={index}
												currencyCode={connector.active_tariff?.currency}
												priceModifierValue={modifier.value}
												priceModifierType={modifier.type}
											/>
										)
									}
								)[0] // Assume that there's at most 1 active Price Modifier (for now).
						}
					</div>
					{isUserSubscribed ? (
						<>
							<span
								className={classNames(
									'caption-3-normal',
									priceModifiersApplied && isAuthenticated ? 'line-through' : null
								)}
							>
								{priceModifiersApplied && isAuthenticated
									? getConnectorTariffPriceValue(connector.active_tariff)
									: getConnectorTariffPriceValue(connector.original_tariff)}
							</span>
						</>
					) : (
						<>
							<span
								className={classNames(
									'caption-3-normal',
									priceModifiersApplied && isAuthenticated ? 'line-through' : null
								)}
							>
								{getConnectorTariffPriceValue(connector.original_tariff)}
							</span>
							{priceModifiersApplied && isAuthenticated && (
								<span className="caption-3-normal">
									{getConnectorTariffPriceValue(connector.active_tariff)}
								</span>
							)}
						</>
					)}
				</div>
			</div>
		</button>
	)
}

const MemoisedConnectorsListItem = memo(ConnectorsListItem)

export default MemoisedConnectorsListItem
