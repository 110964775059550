import { SVGProps, memo } from 'react'

const CheckRadiusIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 20 21',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<rect opacity="0.4" x="0.5" y="1.21973" width="19" height="19" rx="5.5" />
		</svg>
	)
}

const MemoisedCheckRadiusIcon = memo(CheckRadiusIcon)

export default MemoisedCheckRadiusIcon
