import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

type LetterIconProps = {
	textProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, 'children'>
	/**
	 * This should be a single uppercase letter.
	 */
	value: string
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'>

const LetterIcon = ({ className, textProps, value, ...props }: LetterIconProps): JSX.Element => {
	return (
		<div
			className={classNames(
				'flex min-h-5 min-w-5 items-center justify-center rounded-md bg-primary-800',
				className
			)}
			{...props}
		>
			<span {...textProps} className={classNames('body-1-bold text-white', textProps?.className)}>
				{value}
			</span>
		</div>
	)
}

const MemoisedLetterIcon = memo(LetterIcon)

export default MemoisedLetterIcon
