import { memo, MouseEvent } from 'react'
import { classNames } from 'src/_shared/utils/elements'

import { GoogleMapMarkerProps } from '../types'

type LocationsClusterMarkerProps = {
	hasAvailableConnector: boolean
	locationsCount: number
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void
} & GoogleMapMarkerProps

const LocationsClusterMarker = ({
	hasAvailableConnector,
	locationsCount,
	onClick: handleClick
}: LocationsClusterMarkerProps): JSX.Element => {
	const availabilityColour = hasAvailableConnector ? 'bg-success-400' : 'bg-error-300'

	return (
		// Transformation done to reflect the true center of the coordinates
		<div className="absolute -translate-x-1/2 -translate-y-1/2 transform opacity-90">
			{/* Outermost Radial Ring */}
			<button
				className={classNames(
					'animate-fade-in flex h-16 w-16 items-center justify-center rounded-full bg-opacity-10',
					availabilityColour
				)}
				onClick={handleClick}
			>
				<div
					className={classNames(
						'flex h-14 w-14 items-center justify-center rounded-full bg-opacity-30',
						availabilityColour
					)}
				>
					<div
						className={classNames(
							'flex h-12 w-12 items-center justify-center rounded-full bg-opacity-50',
							availabilityColour
						)}
					>
						<div
							className={classNames(
								'flex h-10 w-10 items-center justify-center rounded-full',
								availabilityColour
							)}
						>
							<span className="body-3-medium text-grayscale-100">{locationsCount}</span>
						</div>
					</div>
				</div>
			</button>
		</div>
	)
}

const MemoisedLocationsClusterMarker = memo(LocationsClusterMarker)

export default MemoisedLocationsClusterMarker
