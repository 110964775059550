import {
	ChangeEvent,
	FocusEvent,
	FormEvent,
	Fragment,
	memo,
	useCallback,
	useRef,
	useState
} from 'react'
import Input from 'src/_shared/components/Input'
import Spinner from 'src/_shared/components/Spinner'
import CloseCircleIcon from 'src/_shared/components/_icons/CloseCircleIcon'
import LocationPinFilledIcon from 'src/_shared/components/_icons/LocationPinFilledIcon'
import LocationPinIcon from 'src/_shared/components/_icons/LocationPinIcon'
import useSelectedPlaceSuggestion from 'src/_shared/hooks/useSelectedPlaceSuggestion'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { usePlaceSuggestionsQuery } from 'src/_shared/queries/google'
import { PlaceSuggestion } from 'src/_shared/types/google'
import { classNames } from 'src/_shared/utils/elements'
import { formatDistanceDisplay } from 'src/_shared/utils/format'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'
import { debounce } from 'src/_shared/utils/time'

const PLACE_SUGGESTION_KEY_PREFIX = 'place-suggestion'

const SEARCH_INPUT_DEBOUNCE = 375

interface SearchBarProps {
	className?: string
	endAdornment?: JSX.Element
	onPlaceSuggestionClickCallback?: (placeSuggestion: PlaceSuggestion) => void
}

const LocationSearchBar = ({
	className,
	endAdornment,
	onPlaceSuggestionClickCallback: handlePlaceSuggestionClickCallback
}: SearchBarProps): JSX.Element => {
	const formRef = useRef<HTMLFormElement | null>(null)

	const [selectedPlaceSuggestion, setSelectedPlaceSuggestion] = useSelectedPlaceSuggestion()

	const [searchInputValue, setSearchInputValue] = useState<string>(
		selectedPlaceSuggestion?.structured_formatting.main_text ?? ''
	)

	const { coordinates } = useUserCoordinates()

	const { data: placeSuggestions = [], isLoading: isPlaceSuggestionsLoading } =
		usePlaceSuggestionsQuery(
			{ searchInputValue },
			{
				enabled: !!searchInputValue,
				staleTime: Infinity,
				placeholderData: (previousData): PlaceSuggestion[] => {
					if (searchInputValue && previousData) {
						return previousData // Preserve previous data
					}
					return []
				},
				select: (data): (PlaceSuggestion & { distanceFromUser?: number })[] => {
					if (coordinates) {
						return data
							.map((placeSuggestion): PlaceSuggestion & { distanceFromUser?: number } => {
								return {
									...placeSuggestion,
									distanceFromUser: getDistanceBetweenCoordinates(
										coordinates,
										placeSuggestion.coordinates
									)
								}
							})
							.sort(({ distanceFromUser: a = 0 }, { distanceFromUser: b = 0 }): number => a - b)
					}
					return data
				}
			}
		)

	const handleClearSearchInputClick = useCallback((): void => {
		if (searchInputValue) {
			formRef.current?.reset()
			setSearchInputValue('')
		}
		if (selectedPlaceSuggestion) {
			setSelectedPlaceSuggestion(null)
		}
	}, [searchInputValue, selectedPlaceSuggestion, setSelectedPlaceSuggestion])

	const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
		// Prevent default form submission behaviour
		event.preventDefault()
	}

	/**
	 * Move to and zoom into the selected place and set the search input to it.
	 */
	const handlePlaceSuggestionClick = useCallback(
		(placeSuggestion: PlaceSuggestion) => (): void => {
			handlePlaceSuggestionClickCallback?.(placeSuggestion)
			setSearchInputValue(placeSuggestion.structured_formatting.main_text)
			setSelectedPlaceSuggestion(placeSuggestion)
			formRef.current?.reset()
		},
		[handlePlaceSuggestionClickCallback, setSelectedPlaceSuggestion]
	)

	const handleSearchInputBlur = useCallback(
		(event: FocusEvent<HTMLInputElement>): void => {
			const inputValue = event.currentTarget.value.trim()
			if (inputValue) {
				formRef.current?.reset()
			} else {
				// Selected place suggestion was manually cleared away
				setSelectedPlaceSuggestion(null)
			}
		},
		[setSelectedPlaceSuggestion]
	)

	const handleSearchInputChange = debounce((event: ChangeEvent<HTMLInputElement>): void => {
		setSearchInputValue(event.target.value.toLowerCase().trim())
	}, SEARCH_INPUT_DEBOUNCE)

	return (
		<div className={className}>
			<form className="peer pointer-events-auto" ref={formRef} onSubmit={handleFormSubmit}>
				<div
					className={classNames(
						'rounded-3xl bg-grayscale-100 px-2.5 py-1 shadow-[0_0_4px_0_rgba(0,0,0,0.19)]',
						placeSuggestions.length > 0
							? 'focus-within:rounded-b-none focus-within:rounded-t-3xl'
							: null,
						selectedPlaceSuggestion ? '[&_input]:body-2-semibold [&_input]:text-info-300' : null
					)}
				>
					<Input
						variant="none"
						placeholder="Find charging location here"
						defaultValue={
							selectedPlaceSuggestion?.structured_formatting.main_text ?? searchInputValue
						}
						startAdornment={
							isPlaceSuggestionsLoading ? (
								<Spinner className="h-6 w-6" />
							) : (
								<LocationPinIcon className="h-6 w-6 text-primary-800" />
							)
						}
						endAdornment={
							searchInputValue.length > 0 || selectedPlaceSuggestion ? (
								<button onClick={handleClearSearchInputClick} className="pr-1">
									<CloseCircleIcon className="h-6 w-6 text-error-300" />
								</button>
							) : (
								endAdornment
							)
						}
						onBlur={handleSearchInputBlur}
						onChange={handleSearchInputChange}
					/>
				</div>
			</form>
			{/* Place Suggestions Dropdown */}
			{placeSuggestions.length > 0 && (
				<div className="pointer-events-auto hidden flex-col rounded-b-3xl bg-grayscale-100 px-4 pb-1.5 pt-0.5 shadow-[0_2px_4px_0_rgba(0,0,0,0.19)] peer-focus-within:flex">
					{placeSuggestions.map((placeSuggestion, index): JSX.Element => {
						const formattedDistance = formatDistanceDisplay(placeSuggestion.distanceFromUser)
						return (
							<Fragment key={index}>
								<button
									id={`${PLACE_SUGGESTION_KEY_PREFIX}-${index + 1}`}
									className="flex items-center py-1.5"
									onMouseDown={handlePlaceSuggestionClick(placeSuggestion)}
								>
									{formattedDistance !== null && (
										<div className="mr-0.5 flex min-w-12 max-w-12 flex-col items-center">
											<LocationPinFilledIcon className="mb-0.5 h-4 w-4 text-primary-600" />
											<p className="caption-2-medium text-typography-tertiary">
												{formattedDistance}
											</p>
										</div>
									)}
									<div className="max-w-full">
										<p className="body-1-semibold break-words text-left text-typography-primary">
											{placeSuggestion.structured_formatting.main_text}
										</p>
										<p className="caption-2-normal break-words text-left text-typography-secondary">
											{placeSuggestion.description}
										</p>
									</div>
								</button>
								{/* Divider */}
								{index < placeSuggestions.length - 1 && (
									<div className="border-t-[1px] border-divider-primary" />
								)}
							</Fragment>
						)
					})}
				</div>
			)}
		</div>
	)
}

const MemoisedLocationSearchBar = memo(LocationSearchBar)

export default MemoisedLocationSearchBar
