import { SVGProps, memo } from 'react'

const DirectionsIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 18 19',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} xmlns={xmlns} viewBox={viewBox} {...props}>
			<g clipPath="url(#clip0_8467_46718)">
				<path d="M6.75003 7.78028C6.55112 7.78028 6.36036 7.8593 6.2197 7.99995C6.07905 8.1406 6.00003 8.33136 6.00003 8.53028V11.5303H7.50003V9.28028H9.75003V11.1553L12.375 8.53028L9.75003 5.90528V7.78028H6.75003ZM9.53028 1.32503L16.9553 8.75003C17.0959 8.89067 17.1749 9.0814 17.1749 9.28028C17.1749 9.47915 17.0959 9.66988 16.9553 9.81053L9.53028 17.2355C9.38964 17.3761 9.19891 17.4551 9.00003 17.4551C8.80116 17.4551 8.61043 17.3761 8.46979 17.2355L1.04479 9.81053C0.904182 9.66988 0.825195 9.47915 0.825195 9.28028C0.825195 9.0814 0.904182 8.89067 1.04479 8.75003L8.46979 1.32503C8.61043 1.18442 8.80116 1.10544 9.00003 1.10544C9.19891 1.10544 9.38964 1.18442 9.53028 1.32503Z" />
			</g>
			<defs>
				<clipPath id="clip0_8467_46718">
					<rect width="18" height="18" transform="translate(0 0.280273)" />
				</clipPath>
			</defs>
		</svg>
	)
}

const MemoisedDirectionsIcon = memo(DirectionsIcon)

export default MemoisedDirectionsIcon
