import { DetailedHTMLProps, InputHTMLAttributes, LabelHTMLAttributes, memo, ReactNode } from 'react'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

export type InputProps = {
	/**
	 * Displayed above the component.
	 */
	label?: ReactNode
	/**
	 * The `label` node is wrapped inside of a `label` element.
	 */
	labelProps?: Omit<
		DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>,
		'children' | 'htmlFor'
	>
	/**
	 * Displayed below the component.
	 */
	description?: ReactNode
	/**
	 * Displayed to the left of the `input` element.
	 */
	startAdornment?: ReactNode
	/**
	 * Displayed to the right of the `input` element.
	 */
	endAdornment?: ReactNode
	error?: boolean
	showRequiredMark?: boolean
	type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
	variant?: 'outlined' | 'underlined' | 'none'
	dataTestIdPrefix?: string
} & Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'children' | 'type'
>

const Input = ({
	id,
	className: containerClassName,
	label,
	labelProps,
	description,
	startAdornment,
	endAdornment,
	disabled,
	error,
	required,
	showRequiredMark,
	variant = ((): 'outlined' | 'underlined' | 'none' => {
		switch (BRAND) {
			case Brand.Evme:
				return 'outlined'
			default:
				return 'underlined'
		}
	})(),
	dataTestIdPrefix = '',
	...inputProps
}: InputProps): JSX.Element => {
	return (
		<div
			data-testid={formatDataTestId([dataTestIdPrefix, 'input-box'])}
			className={classNames('flex flex-col', containerClassName)}
		>
			{/* Label */}
			{label && (
				<label
					data-testid={formatDataTestId([dataTestIdPrefix, 'label'])}
					{...labelProps}
					htmlFor={id}
					className={classNames(
						// Base Classes
						'body-2-medium mb-2 text-typography-primary',
						// `variant` Classes
						((): string | null => {
							switch (variant) {
								case 'outlined':
								case 'underlined':
								case 'none':
									return null
							}
						})(),
						labelProps?.className
					)}
				>
					{label}
					{(showRequiredMark === true || (required && showRequiredMark !== false)) && (
						<span className="ml-1 text-error-400">*</span>
					)}
				</label>
			)}
			{/* Input Container */}
			<div
				className={classNames(
					// Base Classes
					'flex items-center duration-150 ease-in-out focus-within:border-input-boundary-focus has-[:invalid]:border-error-300',
					// `variant` Classes
					((): string | null => {
						switch (variant) {
							case 'outlined':
								return 'rounded-md border-2 px-4'
							case 'underlined':
								return 'border-b px-1'
							case 'none':
								return null
						}
					})(),
					// `disabled` Classes
					disabled ? 'cursor-not-allowed bg-input-disabled' : null,
					// Error Class
					error ? 'border-error-300' : 'border-input-boundary',
					// Spacing for Adornments
					!!startAdornment || !!endAdornment ? 'space-x-3' : null
				)}
			>
				{/* Left Adornment */}
				{startAdornment}
				<div className="flex-grow">
					<input
						data-testid={formatDataTestId([dataTestIdPrefix, 'input'])}
						{...inputProps}
						id={id}
						className="body-2-normal h-12 w-full bg-transparent text-typography-primary placeholder-input-empty disabled:cursor-not-allowed disabled:text-typography-secondary"
						disabled={disabled}
						required={required}
					/>
				</div>
				{/* Right Adornment */}
				{endAdornment}
			</div>
			{/* Description */}
			{description && (
				<div
					data-testid={formatDataTestId([dataTestIdPrefix, 'input-desc'])}
					className={classNames(
						// Base Classes
						'caption-3-medium mt-1',
						// Error Class
						error ? 'text-error-300' : 'text-typography-primary'
					)}
				>
					{description}
				</div>
			)}
		</div>
	)
}

const MemoisedInput = memo(Input)

export default MemoisedInput
