import { useCallback, useMemo } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import LocationFilterButton from 'src/_shared/components/LocationFilterButton'
import LocationFiltersModalWrapper from 'src/_shared/components/LocationFiltersModalWrapper'
import LocationSearchBar from 'src/_shared/components/LocationSearchBar'
import QuickFilterButtons from 'src/_shared/components/QuickFilterButtons'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import useSelectedPlaceSuggestion from 'src/_shared/hooks/useSelectedPlaceSuggestion'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { useUserZoomCoordinates } from 'src/_shared/hooks/useUserZoomCoordinates'
import { Coordinates } from 'src/_shared/types/location'
import LocationsListView from 'src/screens/LocationsListingScreen/components/LocationsListView'
import LocationsNearbyListView from 'src/screens/LocationsListingScreen/components/LocationsNearbyListView'

const LocationsListingScreen = (): JSX.Element => {
	const navigate = useRouterNavigate()

	const { coordinates } = useUserCoordinates()

	const { userZoomCoordinates } = useUserZoomCoordinates()

	const [selectedPlaceSuggestion] = useSelectedPlaceSuggestion()

	const searchCoordinates = useMemo((): Coordinates | null => {
		// prioritize selectedPlaceSuggestion if there is any

		if (selectedPlaceSuggestion) {
			return selectedPlaceSuggestion.coordinates
		}

		return coordinates
	}, [coordinates, selectedPlaceSuggestion])

	const handleBackClick = useCallback((): void => {
		if (userZoomCoordinates?.zoomCoordinates) {
			const { zoomCoordinates, zoom = DEFAULT_ZOOM } = userZoomCoordinates
			const { lat, lng } = zoomCoordinates
			navigate({
				pathname: ScreenRoutePath.Map,
				search: `lat=${lat}&lng=${lng}&zoom=${zoom}`
			})
		} else {
			navigate(ScreenRoutePath.Map)
		}
	}, [userZoomCoordinates, navigate])

	return (
		<LocationFiltersModalWrapper>
			<ScreenContainer
				contentViewProps={{
					className: 'relative'
				}}
				topBarProps={{
					centerRender: <h1>Charger List</h1>,
					leftRender: (
						<TopBarButton data-testid="hs-btn-back" onClick={handleBackClick}>
							<ArrowLeftIcon className="h-4" />
						</TopBarButton>
					),
					className: 'shadow-none'
				}}
			>
				{/* Search Bar Portion */}
				<LocationSearchBar
					className="pointer-events-none absolute top-0 z-10 h-full w-full px-2.5 pt-3 has-[input:focus]:bg-black/40"
					endAdornment={<LocationFilterButton />}
				/>
				<div className="mt-20" />
				{/* Filter Buttons */}
				<QuickFilterButtons className="mb-3 flex px-2.5" />
				{/* Header Bar Portion */}
				<div className="flex items-center justify-between border-t px-4 py-1.5 shadow-md">
					<span className="body-2-semibold text-typography-primary">Charging Points</span>
					<span className="body-2-normal text-typography-secondary">Available / Total</span>
				</div>
				{/* Location List */}
				{searchCoordinates !== null ? (
					<LocationsNearbyListView coordinates={searchCoordinates} />
				) : (
					<LocationsListView />
				)}
			</ScreenContainer>
		</LocationFiltersModalWrapper>
	)
}

export default LocationsListingScreen
