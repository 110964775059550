import { useCallback } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'
import { LocationFilters } from 'src/_shared/types/filter'

const DEFAULT_ACTIVE_FILTER: LocationFilters = {
	cpoEntityCodes: null,
	distanceRange: null,
	powerType: null,
	subscriptionStatus: null
}

const LOCATION_FILTERS_KEY = 'locationFilters'

export const useLocationFilters = (): {
	locationFilters: LocationFilters
	setFilter: <T extends keyof LocationFilters>(key: T, value: LocationFilters[T]) => void
	setFilters: (value: LocationFilters | null) => void
} => {
	const [locationFilters, setLocationFilters] =
		useSessionStorage<LocationFilters>(LOCATION_FILTERS_KEY)

	const setFilters = useCallback(
		(value: LocationFilters | null) => {
			setLocationFilters(value)
		},
		[setLocationFilters]
	)

	const setFilter = useCallback(
		<T extends keyof LocationFilters>(key: T, value: LocationFilters[T]) => {
			const newLocationFilters = {
				...DEFAULT_ACTIVE_FILTER, // set the default filters
				...locationFilters, // for the filters that are not updated
				[key]: value // lastly, update the filter
			}

			setLocationFilters(newLocationFilters)
		},
		[locationFilters, setLocationFilters]
	)

	return { locationFilters: locationFilters ?? DEFAULT_ACTIVE_FILTER, setFilter, setFilters }
}
