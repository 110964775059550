import { SVGProps, memo } from 'react'

const CompassIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	strokeWidth = '1.5',
	viewBox = '0 0 20 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25Z"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
			<path
				d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
			<path
				d="M10 3.33366V1.66699"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
			<path
				d="M3.33341 10H1.66675"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
			<path
				d="M10 16.667V18.3337"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
			<path
				d="M16.6667 10H18.3334"
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeLinecap={strokeLinecap}
				strokeLinejoin={strokeLinejoin}
			/>
		</svg>
	)
}

const MemoisedCompassIcon = memo(CompassIcon)

export default MemoisedCompassIcon
