import { memo, useMemo } from 'react'
import Spinner from 'src/_shared/components/Spinner'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { LocationsNearbyPage, useLocationsNearbyInfiniteQuery } from 'src/_shared/queries/locations'
import { Coordinates } from 'src/_shared/types/location'
import { OmniLocationNearby } from 'src/_shared/types/omni/location'
import { classNames } from 'src/_shared/utils/elements'
import { filterLocationWithSubscriptionStatusAndDistance } from 'src/_shared/utils/filter'

import LocationListController from './LocationsListController'

const QUERY_RADIUS_MAX = 1200 // Metres

interface LocationsNearbyListViewProps {
	coordinates: Coordinates
}

const LocationsNearbyListView = ({ coordinates }: LocationsNearbyListViewProps): JSX.Element => {
	const { locationFilters } = useLocationFilters()

	const { coordinates: userCoordinates } = useUserCoordinates()

	const { user } = useAuthContext()

	const {
		data: locationsData = {
			pages: []
		},
		isLoading,
		hasNextPage,
		fetchNextPage
	} = useLocationsNearbyInfiniteQuery({
		latitude: coordinates.latitude,
		longitude: coordinates.longitude,
		radius: QUERY_RADIUS_MAX,
		entityCodes: locationFilters.cpoEntityCodes?.join(','),
		powerType: locationFilters.powerType
	})

	const locations = useMemo((): OmniLocationNearby[] => {
		return locationsData.pages.reduce(
			(acc: OmniLocationNearby[], locationPage: LocationsNearbyPage) => {
				const flattenLocations = locationPage.data.reduce<OmniLocationNearby[]>(
					(locations, { location = {}, distance_meters: distanceMetres }): OmniLocationNearby[] => {
						if (
							!filterLocationWithSubscriptionStatusAndDistance(
								location,
								userCoordinates,
								user?.subscribedCpoEntities ?? [],
								locationFilters
							)
						) {
							return locations
						}

						return locations.concat({ location, distance_meters: distanceMetres })
					},
					[]
				)

				return [...acc, ...flattenLocations]
			},
			[]
		)
	}, [locationFilters, locationsData.pages, user?.subscribedCpoEntities, userCoordinates])

	return (
		<div
			className={classNames(
				'flex w-full flex-grow',
				isLoading ? 'items-center justify-center' : null
			)}
		>
			{isLoading ? (
				<Spinner />
			) : (
				<LocationListController
					locations={locations}
					onFetchNextPageCallback={hasNextPage ? fetchNextPage : undefined}
				/>
			)}
		</div>
	)
}

const MemoisedLocationsNearbyListView = memo(LocationsNearbyListView)

export default MemoisedLocationsNearbyListView
