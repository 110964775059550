import { SVGProps, memo } from 'react'

const BottomBarScanQrIcon = ({
	fill = 'white',
	viewBox = '0 0 73 73',
	xmlns = 'http://www.w3.org/2000/svg',
	fillRule = 'evenodd',
	clipRule = 'evenodd',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			clipRule={clipRule}
			fillRule={fillRule}
			{...props}
		>
			<path d="M54.0945 22.4706C54.0945 20.1936 51.9577 18.3477 49.3217 18.3477H42.9581C42.0794 18.3477 41.3672 18.963 41.3672 19.722C41.3672 20.481 42.0794 21.0963 42.9581 21.0963H49.3217C50.2004 21.0963 50.9127 21.7116 50.9127 22.4706V27.9678C50.9127 28.7268 51.6249 29.3421 52.5036 29.3421C53.3822 29.3421 54.0945 28.7268 54.0945 27.9678V22.4706Z" />
			<path d="M54.0945 38.9622C54.0945 38.2032 53.3822 37.5879 52.5036 37.5879C51.6249 37.5879 50.9127 38.2032 50.9127 38.9622V44.4594C50.9127 45.2185 50.2004 45.8337 49.3217 45.8337H42.9581C42.0794 45.8337 41.3672 46.449 41.3672 47.2081C41.3672 47.9671 42.0794 48.5824 42.9581 48.5824H49.3217C51.9577 48.5824 54.0945 46.7365 54.0945 44.4594V38.9622Z" />
			<path d="M23.8655 45.8337C22.9869 45.8337 22.2746 45.2185 22.2746 44.4594V38.9622C22.2746 38.2032 21.5623 37.5879 20.6837 37.5879C19.8051 37.5879 19.0928 38.2032 19.0928 38.9622V44.4594C19.0928 46.7365 21.2296 48.5824 23.8655 48.5824H30.2292C31.1078 48.5824 31.8201 47.9671 31.8201 47.2081C31.8201 46.449 31.1078 45.8337 30.2292 45.8337H23.8655Z" />
			<path d="M19.0928 27.9678C19.0928 28.7268 19.8051 29.3421 20.6837 29.3421C21.5623 29.3421 22.2746 28.7268 22.2746 27.9678V22.4706C22.2746 21.7116 22.9869 21.0963 23.8655 21.0963H30.2292C31.1078 21.0963 31.8201 20.481 31.8201 19.722C31.8201 18.963 31.1078 18.3477 30.2292 18.3477H23.8655C21.2296 18.3477 19.0928 20.1936 19.0928 22.4706V27.9678Z" />
			<path d="M35.002 25.219C35.002 24.46 34.2898 23.8447 33.4111 23.8447H27.0475C26.1688 23.8447 25.4565 24.46 25.4565 25.219V30.7163C25.4565 31.4753 26.1688 32.0906 27.0475 32.0906H33.4111C34.2898 32.0906 35.002 31.4753 35.002 30.7163V25.219ZM31.8202 27.2805C31.8202 26.901 31.4641 26.5933 31.0247 26.5933H29.4338C28.9945 26.5933 28.6384 26.901 28.6384 27.2805V28.6548C28.6384 29.0343 28.9945 29.342 29.4338 29.342H31.0247C31.4641 29.342 31.8202 29.0343 31.8202 28.6548V27.2805Z" />
			<path d="M47.8419 25.219C47.8419 24.46 47.1296 23.8447 46.2509 23.8447H39.8873C39.0087 23.8447 38.2964 24.46 38.2964 25.219V30.7163C38.2964 31.4753 39.0087 32.0906 39.8873 32.0906H46.2509C47.1296 32.0906 47.8419 31.4753 47.8419 30.7163V25.219ZM44.66 27.2805C44.66 26.901 44.3039 26.5933 43.8646 26.5933H42.2737C41.8344 26.5933 41.4782 26.901 41.4782 27.2805V28.6548C41.4782 29.0343 41.8344 29.342 42.2737 29.342H43.8646C44.3039 29.342 44.66 29.0343 44.66 28.6548V27.2805Z" />
			<path d="M33.5029 34.8389C34.3816 34.8389 35.0938 35.4541 35.0938 36.2132V41.7104C35.0938 42.4694 34.3816 43.0847 33.5029 43.0847H27.1393C26.2606 43.0847 25.5483 42.4694 25.5483 41.7104V36.2132C25.5483 35.4541 26.2606 34.8389 27.1393 34.8389H33.5029ZM28.7302 38.2746C28.7302 37.8952 29.0864 37.5875 29.5256 37.5875H31.1165C31.5558 37.5875 31.912 37.8952 31.912 38.2746V39.6489C31.912 40.0284 31.5558 40.3361 31.1165 40.3361H29.5256C29.0864 40.3361 28.7302 40.0284 28.7302 39.6489V38.2746Z" />
			<path d="M46.3481 43.3613C47.2267 43.3613 47.939 42.746 47.939 41.987C47.939 41.228 47.2267 40.6127 46.3481 40.6127C45.4694 40.6127 45.6046 40.6127 45.1904 40.6127H44.7762C44.362 40.6127 44.401 40.6127 43.9617 40.6127H39.9844C39.1057 40.6127 38.3935 41.228 38.3935 41.987C38.3935 42.746 39.1057 43.3613 39.9844 43.3613H46.3481Z" />
			<path d="M46.6377 37.6689C47.3625 37.6689 48.0874 37.0282 48.0874 36.2377C48.0874 35.4472 47.0952 34.8065 46.6377 34.8065C46.1802 34.8065 46.6648 34.8065 46.4491 34.8065H46.2334C46.0178 34.8065 46.038 34.8065 45.8093 34.8065H44.8773C44.4198 34.8065 42.9099 34.9183 42.9099 36.2377C42.9099 37.6689 44.4198 37.6689 44.8773 37.6689H46.6377Z" />
			<path d="M40.0392 34.9688C39.1605 34.9688 38.4482 35.584 38.4482 36.3431C38.4482 37.1021 39.1605 37.7174 40.0392 37.7174C40.9178 37.7174 41.6301 37.1021 41.6301 36.3431C41.6301 35.584 40.9178 34.9688 40.0392 34.9688Z" />
		</svg>
	)
}

const MemoisedBottomScanQrIcon = memo(BottomBarScanQrIcon)

export default MemoisedBottomScanQrIcon
