import { useEffect, useMemo, useState } from 'react'

const useDebounce = <T>(value: T, delay = 375): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	// To handle equality comparison between objects.
	const stringifiedValue = useMemo((): string => {
		return JSON.stringify(value)
	}, [value])

	useEffect((): (() => void) => {
		const timeoutId: NodeJS.Timeout = setTimeout((): void => {
			try {
				const parsedValue = JSON.parse(stringifiedValue) as unknown as T
				setDebouncedValue(parsedValue)
			} catch (error) {
				console.error('[useDebounce]', error)
			}
		}, delay)
		return (): void => {
			clearTimeout(timeoutId)
		}
	}, [delay, stringifiedValue])

	return debouncedValue
}

export default useDebounce
