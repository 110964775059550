import { ConnectorPowerType, SubscriptionStatus } from 'src/_shared/enums/filter'
import { LocationFilters } from 'src/_shared/types/filter'
import { Coordinates } from 'src/_shared/types/location'
import { OmniEvse } from 'src/_shared/types/omni'
import { OmniLocation } from 'src/_shared/types/omni/location'
import { getConnectorPowerType, hasAvailableConnectors } from 'src/_shared/utils/charging'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'

/**
 * Checks if an EVSE has a connector with the specified power type.
 *
 * @param {OmniEvse} evse - The EVSE object to check.
 * @param {ConnectorPowerType | null} powerTypeFilter - The power type to filter by. If null, the function returns true.
 * @returns {boolean} A boolean indicating whether the EVSE has a connector with the specified power type.
 */
export const hasConnectorWithPowerType = (
	evse: OmniEvse,
	powerTypeFilter: ConnectorPowerType | null
): boolean => {
	if (powerTypeFilter) {
		return (
			!!evse.connectors &&
			evse.connectors.some((connector) => getConnectorPowerType(connector) === powerTypeFilter)
		)
	}

	return true
}

/**
 * Filters evses based on the presence of connectors
 * and an optional power type filter.
 *
 * @param {OmniEvse[]} evses - The list of evses to filter.
 * @param {ConnectorPowerType | null} powerTypeFilter - The power type filter to apply. If null, no power type filtering is applied.
 * @returns {OmniEvse[]} - The filtered list of evses that have connectors and match the power type filter.
 */
export const filterEvsesByPowerType = (
	evses: OmniEvse[] = [],
	powerTypeFilter: ConnectorPowerType | null
): OmniEvse[] => {
	const allEvses = evses.filter((evse) => !!evse.connectors && evse.connectors.length > 0)

	const filteredEvsesByPower = allEvses.filter((evse) => {
		return hasConnectorWithPowerType(evse, powerTypeFilter)
	})
	return filteredEvsesByPower
}

/**
 * Filters a location based on subscription status and distance from current coordinates.
 * These logic are decoupled out from the main filter function
 * as this will be used to filter locations returned from CPO BE.
 *
 * @param {OmniLocation} location - The location to be filtered.
 * @param {Coordinates | null} currentCoords - The current coordinates of the user.
 * @param {string[]} subscribedCpoEntities - A list of subscribed CPO entity codes.
 * @param {LocationFilters} locationFilters - The filters to apply on the location.
 * @returns {boolean} A boolean indicating whether the location passes the filters.
 */
export const filterLocationWithSubscriptionStatusAndDistance = (
	location: OmniLocation,
	currentCoords: Coordinates | null,
	subscribedCpoEntities: string[],
	locationFilters: LocationFilters
): boolean => {
	// 1. Check if the location is within the distance range
	if (
		currentCoords?.latitude &&
		currentCoords.longitude &&
		location.coordinates?.latitude &&
		location.coordinates.longitude
	) {
		const locationCoords: Coordinates = {
			latitude: Number(location.coordinates.latitude),
			longitude: Number(location.coordinates.longitude)
		}

		const distance = getDistanceBetweenCoordinates(locationCoords, currentCoords)

		if (
			locationFilters.distanceRange !== null &&
			(distance < locationFilters.distanceRange[0] || distance > locationFilters.distanceRange[1])
		) {
			return false
		}
	}

	// 2. Check if the location is filtered by subscription status
	if (locationFilters.subscriptionStatus !== null) {
		const isLocationSubscribed = subscribedCpoEntities.includes(location.entity_code ?? '')
			? SubscriptionStatus.Subscribed
			: SubscriptionStatus.NotSubscribed

		if (locationFilters.subscriptionStatus !== isLocationSubscribed) {
			return false
		}
	}

	return true
}

/**
 * Filters a location based on various location filters including
 * power type, CPO entity code, subscription status, and distance.
 *
 * @param {OmniLocation} location - The location to be filtered.
 * @param {Coordinates | null} currentCoords - The current coordinates of the user.
 * @param {string[]} subscribedCpoEntities - A list of subscribed CPO entity codes.
 * @param {LocationFilters} locationFilters - The filters to apply on the location.
 * @returns {boolean} A boolean indicating whether the location passes the filters.
 */
export const filterLocationWithLocationFilters = (
	location: OmniLocation,
	currentCoords: Coordinates | null,
	subscribedCpoEntities: string[],
	locationFilters: LocationFilters
): boolean => {
	// 1. Check if the location has connectors based on the current powerTypeFilter
	if (filterEvsesByPowerType(location.evses, locationFilters.powerType).length === 0) {
		return false
	}

	// 2. Check if the location is filtered by CPO
	if (
		locationFilters.cpoEntityCodes !== null &&
		!locationFilters.cpoEntityCodes.includes(location.entity_code ?? '')
	) {
		return false
	}

	// 3. Check if the location is within the distance range + subscription status
	if (
		!filterLocationWithSubscriptionStatusAndDistance(
			location,
			currentCoords,
			subscribedCpoEntities,
			locationFilters
		)
	) {
		return false
	}

	return true
}

/**
 * Filters the connectors of a given location based on the specified location filters.
 *
 * @param {OmniLocation} location - The location to be filtered
 * @param {LocationFilters} locationFilters - The filters to apply on the location's evses.
 * @returns {boolean} A boolean indicating whether any of the filtered evses have available connectors.
 */
export const filterConnectorsWithLocationFilters = (
	location: OmniLocation,
	locationFilters: LocationFilters
): boolean => {
	const filteredEvses = filterEvsesByPowerType(location.evses, locationFilters.powerType)

	return filteredEvses.some(
		(evse) => evse.connectors !== undefined && hasAvailableConnectors(evse.connectors)
	)
}
