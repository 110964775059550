import dayjs from 'dayjs'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
	ScreenRoutePath,
	useQueryParams,
	useRouterLocation,
	useRouterNavigate
} from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import Notice from 'src/_shared/components/Notice'
import PoweredByFooter from 'src/_shared/components/PoweredByFooter'
import Skeleton from 'src/_shared/components/Skeleton'
import Spinner from 'src/_shared/components/Spinner'
import { APP_MODE, BACK_LINK, BRAND, TIME_FORMAT } from 'src/_shared/constants/env'
import { TRANSIENT_SESSION_ID_KEY } from 'src/_shared/constants/storage'
import { AppMode, Brand } from 'src/_shared/enums/env'
import {
	OmniConnectorPowerType,
	OmniConnectorStatus,
	OmniEvseCapability,
	OmniSessionStatus
} from 'src/_shared/enums/omni'
import { PaymentMethodCode, PaymentTypeCode } from 'src/_shared/enums/payments'
import { useLocalStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import {
	StartChargingSessionMutationParams,
	useStartChargingSessionMutation
} from 'src/_shared/mutations/sessions'
import { classNames } from 'src/_shared/utils/elements'
import { formatCurrencyValue } from 'src/_shared/utils/string'

import { useIsUserSubscribed } from '../../../_shared/hooks/useIsUserSubscribed'
import { BLACKOUT_PERIOD_TIME_FORMAT, DAY_NUMBER_TO_STRING } from '../constants'
import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerItemLists } from '../hooks/useChargerItemLists'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps, ChargerScreenQueryParams } from '../types'
import { formatTimeToString, getTimeFromSeconds } from '../utils'
import ChargingHeader from './ChargingHeader'
import DetailsGrid from './DetailsGrid'
import Footer from './Footer'
import StateOfChargeCircle from './StateOfChargeCircle'
import TariffModal from './TariffModal'
import TermsAndConditionsBottomRender from './TermsAndConditionsBottomRender'

type PreChargingViewProps = Omit<ChargerScreenCommonViewProps, 'onNext'>

interface FooterRenders {
	buttonText?: ReactNode
	isButtonTextNext?: boolean
	connectorStatusText?: ReactNode
	connectorStatusTextClassName?: string
	onButtonClick?: () => void
}

const PreChargingView = ({
	isAuthenticated = false,
	routeParams,
	updateView
}: PreChargingViewProps): JSX.Element => {
	const [isIntermediateModalOpen, setIsIntermediateModalOpen] = useState<boolean>(false)

	const [isTariffModalOpen, setIsTariffModalOpen] = useState<boolean>(false)

	const intl = useIntl()

	const [{ payment_success: paymentSuccess = 'false', vehicle_id: vehicleId }, setQueryParams] =
		useQueryParams<ChargerScreenQueryParams>()

	const { key: historyKey, pathname } = useRouterLocation()

	const navigate = useRouterNavigate()

	const [transientSessionId, setTransientSessionId] = useLocalStorageItem(TRANSIENT_SESSION_ID_KEY)

	const isUserSubscribed = useIsUserSubscribed(routeParams.cpoEntityCode)

	const { getFeatureToggles } = useStrapiContext()

	const {
		location,
		evse,
		connector,
		activeTariff,
		locationError,
		connectorError,
		locationQueryStatus
	} = useChargerDetails({
		...routeParams,
		enableActiveTariffQuery: true
	})

	const {
		session,
		isUserChargingSession,
		isUserActiveChargingSession,
		isUserActiveChargingSessionElsewhere,
		chargerSessionDetailsQueryStatus,
		resetChargerSessionDetailsQueries
	} = useChargerSessionDetails(routeParams)

	const {
		isPending: isStartChargingSessionPending,
		isSuccess: isStartChargingSessionSuccess,
		mutateAsync: startChargingSession
	} = useStartChargingSessionMutation()

	const isStartChargingSessionLoading =
		isStartChargingSessionPending || isStartChargingSessionSuccess

	const isTransientFlow = APP_MODE === AppMode.Transient

	const isChargerSessionDetailsSuccess = chargerSessionDetailsQueryStatus === 'success'

	const isLocationPending = locationQueryStatus === 'pending'

	const { skipIntermediateModal } = useMemo((): Partial<{ skipIntermediateModal: boolean }> => {
		return getFeatureToggles('chargerScreen')?.preChargingView ?? {}
	}, [getFeatureToggles])

	const toggleIntermediateModal = useCallback((): void => {
		if (!isStartChargingSessionLoading) {
			setIsIntermediateModalOpen((isOpen): boolean => !isOpen)
		}
	}, [isStartChargingSessionLoading])

	const toggleTariffModal = useCallback((): void => {
		setIsTariffModalOpen((isOpen): boolean => !isOpen)
	}, [])

	const { gridItemList, tariffInformationItemList } = useChargerItemLists({
		location,
		connector,
		activeTariff,
		session: null,
		isAuthenticated,
		isUserSubscribed,
		toggleTariffModal
	})

	const handleNext = useCallback((): void => {
		// Handle Transient Flow
		if (isTransientFlow) {
			updateView?.(ChargerScreenViewItemKey.TransientEmailView)
		}
		// Handle Default and SSO Flows
		else {
			const isBrandExternalPayment = [Brand.Evme].includes(BRAND)

			// Attempt to start the charging session if:
			// 1. The user is subscribed.
			// 2. There are no costs for using the charger.
			// 3. The current `BRAND` has their own payment handling, e.g. EVme.
			if (
				isUserSubscribed ||
				!activeTariff ||
				(!activeTariff.price_per_kwh && !activeTariff.price_per_min) ||
				isBrandExternalPayment
			) {
				const params = ((): StartChargingSessionMutationParams => {
					if (isBrandExternalPayment) {
						return {
							...routeParams,
							paymentMethodCode: ((): PaymentMethodCode => {
								switch (BRAND) {
									case Brand.Evme:
										return PaymentMethodCode.Evme
									default:
										return PaymentMethodCode.Unknown
								}
							})(),
							paymentTypeCode: PaymentTypeCode.Redirect,
							vehicleId
						}
					}
					return routeParams
				})()

				void startChargingSession(params, {
					onError: (): void => {
						updateView?.(ChargerScreenViewItemKey.ErrorView, {
							type: 'error',
							header: intl.formatMessage({
								id: 'PreChargingView.TitleStartChargeFailure',
								defaultMessage: 'Failed To Start Charge'
							}),
							description: intl.formatMessage({
								id: 'PreChargingView.DescriptionStartChargeFailure',
								defaultMessage: 'Please try again later'
							})
						})
					},
					onSuccess: (response): void => {
						const handleStartChargingSuccess = async (): Promise<void> => {
							const paymentRedirectUrl = response.data.data?.payment_url
							if (paymentRedirectUrl) {
								console.debug(
									`[${ChargerScreenViewItemKey.PreChargingView}] Redirecting to:`,
									paymentRedirectUrl
								)
								window.location.href = paymentRedirectUrl
							} else {
								await resetChargerSessionDetailsQueries()
								updateView?.(ChargerScreenViewItemKey.ChargingView)
							}
						}
						void handleStartChargingSuccess()
					}
				})
			} else {
				updateView?.(ChargerScreenViewItemKey.PaymentMethodsView)
			}
		}
	}, [
		activeTariff,
		intl,
		isTransientFlow,
		isUserSubscribed,
		routeParams,
		vehicleId,
		resetChargerSessionDetailsQueries,
		startChargingSession,
		updateView
	])

	/**
	 * Where applicable, renders the content displayed inside of the intermediate modal.
	 * If this evaluates to `null`, then the intermediate modal should not open up.
	 */
	const intermediateModalContentRender = useMemo((): JSX.Element | null => {
		// Feature Toggle to skip the display of intermediate modal
		if (skipIntermediateModal) {
			return null
		}

		// Handle Transient Flow
		if (isTransientFlow) {
			// Display provided `tariff_alt_text`.
			if (activeTariff?.tariff_alt_text?.some(({ text }): boolean => !!text?.trim().length)) {
				return (
					<>
						<h1 className="mb-2 text-center">
							<FormattedMessage id="PreChargingView.ModalTitleNotice" defaultMessage="Notice" />
						</h1>
						{activeTariff.tariff_alt_text.map(({ text }, index): JSX.Element | null => {
							const displayText = text?.trim()
							if (displayText) {
								return (
									<p key={index} className="body-2-light mb-8 text-center">
										{displayText}
									</p>
								)
							}
							return null
						})}
					</>
				)
			}
			return null
		}
		// Handle Default and SSO Flows
		else {
			// Idle Fees Reminder
			if (activeTariff?.price_per_min_parked) {
				const today = DAY_NUMBER_TO_STRING[dayjs().get('day')]

				const hasBlackoutPeriodToday =
					activeTariff.idle_blackout_period?.day_of_week?.includes(today)

				const blackoutStartTime = dayjs(
					activeTariff.idle_blackout_period?.start_time_of_day,
					BLACKOUT_PERIOD_TIME_FORMAT
				).format(TIME_FORMAT)

				const blackoutEndTime = dayjs(
					activeTariff.idle_blackout_period?.end_time_of_day,
					BLACKOUT_PERIOD_TIME_FORMAT
				).format(TIME_FORMAT)

				const hoursAbbreviated = intl.formatMessage({
					id: 'formatTimeToString.LabelValueHourAbbreviated',
					defaultMessage: 'h'
				})

				const minutesAbbreviated = intl.formatMessage({
					id: 'formatTimeToString.LabelValueMinuteAbbreviated',
					defaultMessage: 'min'
				})

				const secondsAbbreviated = intl.formatMessage({
					id: 'formatTimeToString.LabelValueSecondAbbreviated',
					defaultMessage: 's'
				})

				const timeFormat: Record<string, [string, string]> = {
					hoursFormat: [hoursAbbreviated, hoursAbbreviated],
					minutesFormat: [minutesAbbreviated, minutesAbbreviated],
					secondsFormat: [secondsAbbreviated, secondsAbbreviated]
				}

				const gracePeriodSeconds = activeTariff.grace_period_seconds ?? 0

				const graceDuration = formatTimeToString(getTimeFromSeconds(gracePeriodSeconds), timeFormat)

				const idleCostMaximum = activeTariff.max_price_per_min_parked

				const idleCostRate = activeTariff.price_per_min_parked.incl_vat

				return (
					<>
						<h1 className="mb-2 text-center">
							<FormattedMessage id="PreChargingView.ModalTitleReminder" defaultMessage="Reminder" />
						</h1>
						<p
							className={classNames(
								'body-2-light text-center',
								hasBlackoutPeriodToday ? 'mb-2' : 'mb-8'
							)}
						>
							<FormattedMessage
								id="PreChargingView.ModalDescriptionIdleFeesRate"
								defaultMessage="Idling fees may apply at a rate of <b>{pricePerMin}/min</b> at a cap of <b>{maxPrice}</b> after a grace period of <b>{graceDuration}</b>. Please vacate the lot when charging is complete."
								values={{
									b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
									pricePerMin: formatCurrencyValue(idleCostRate, activeTariff.currency),
									maxPrice: formatCurrencyValue(idleCostMaximum, activeTariff.currency, 2),
									graceDuration: graceDuration
								}}
							/>
						</p>
						{hasBlackoutPeriodToday && (
							<p className="body-2-light mb-8 text-center">
								<FormattedMessage
									id="PreChargingView.ModalDescriptionIdleFeesBlackoutPeriod"
									defaultMessage="Idling fees will not be applied from <b>{blackoutStartTime}</b> to <b>{blackoutEndTime}</b>."
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
										blackoutStartTime,
										blackoutEndTime
									}}
								/>
							</p>
						)}
					</>
				)
			}
		}
		return null
	}, [activeTariff, intl, isTransientFlow, skipIntermediateModal])

	const footerRenders = useMemo((): FooterRenders => {
		/**
		 * Returns the user to the previous page found in history.
		 * If there is no history, this defaults to Map screen.
		 */
		const handleBackClick = (): void => {
			const hasPreviousHistory = historyKey !== 'default'
			if (hasPreviousHistory) {
				navigate(-1)
			} else {
				if (BACK_LINK) {
					window.location.href = BACK_LINK
				} else {
					navigate(ScreenRoutePath.Root)
				}
			}
		}

		const handleLoginClick = (): void => {
			navigate({
				pathname: ScreenRoutePath.AccountLogin,
				search: `redirect=${pathname}`
			})
		}

		if (isUserActiveChargingSessionElsewhere) {
			return {
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextViewChargingSession',
					defaultMessage: 'View Charging Session'
				}),
				connectorStatusText: intl.formatMessage({
					id: 'PreChargingView.ConnectorStatusTextOngoing',
					defaultMessage:
						'You have an ongoing charge session. Please end the ongoing session to start a new session.'
				}),
				connectorStatusTextClassName: 'text-error-300',
				onButtonClick: (): void => {
					const sessionId = session?._id ?? ''
					navigate([ScreenRoutePath.Session, sessionId])
				}
			}
		}
		// Connector is offline
		else if (connector?.online === false) {
			return {
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				connectorStatusText: intl.formatMessage({
					id: 'PreChargingView.ConnectorStatusTextOffline',
					defaultMessage: 'Charger Offline'
				}),
				connectorStatusTextClassName: 'text-error-300',
				onButtonClick: handleBackClick
			}
		}
		// Connector is disabled
		else if (connector?.is_enabled === false) {
			return {
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				connectorStatusText: intl.formatMessage({
					id: 'PreChargingView.ConnectorStatusTextUnavailable',
					defaultMessage: 'Charger Unavailable'
				}),
				connectorStatusTextClassName: 'text-error-300',
				onButtonClick: handleBackClick
			}
		}
		// Connector has reservation capabilities; do not allow usage of the charger.
		// FUTURE TODO: Handle Reservation.
		else if (
			evse?.capabilities?.some((capability): boolean => {
				return capability === OmniEvseCapability.ReservationRequired
			})
		) {
			return {
				buttonText: intl.formatMessage({
					id: 'PreChargingView.ButtonTextBack',
					defaultMessage: 'Back'
				}),
				connectorStatusText: intl.formatMessage({
					id: 'PreChargingView.ConnectorStatusTextReservationComingSoon',
					defaultMessage:
						'This charger requires a reservation (coming soon). Please try another station nearby.'
				}),
				connectorStatusTextClassName: 'text-error-300',
				onButtonClick: handleBackClick
			}
		} else if (connector?.status) {
			switch (connector.status) {
				case OmniConnectorStatus.Available:
					return {
						connectorStatusText: intl.formatMessage({
							id: 'PreChargingView.ConnectorStatusTextAvailable',
							defaultMessage: 'Please Connect Charger'
						}),
						connectorStatusTextClassName: 'text-error-300',
						buttonText:
							isAuthenticated || isTransientFlow
								? intl.formatMessage({
										id: 'PreChargingView.ButtonTextNext',
										defaultMessage: 'Next'
									})
								: intl.formatMessage({
										id: 'PreChargingView.ButtonTextLogin',
										defaultMessage: 'Login'
									}),
						isButtonTextNext: isAuthenticated || isTransientFlow,
						onButtonClick: !isAuthenticated && !isTransientFlow ? handleLoginClick : undefined
					}
				case OmniConnectorStatus.Preparing:
					return {
						connectorStatusText: intl.formatMessage({
							id: 'PreChargingView.ConnectorStatusTextReady',
							defaultMessage: 'Charger Ready!'
						}),
						connectorStatusTextClassName: 'text-primary-800',
						...(isAuthenticated || isTransientFlow
							? // Proceed to payment method selection and
								// start charging after successful payment.
								{
									buttonText: intl.formatMessage({
										id: 'PreChargingView.ButtonTextNext',
										defaultMessage: 'Next'
									}),
									isButtonTextNext: true,
									onButtonClick: intermediateModalContentRender
										? toggleIntermediateModal
										: handleNext
								}
							: // User has to login before they are able to start charging.
								{
									buttonText: intl.formatMessage({
										id: 'PreChargingView.ButtonTextLogin',
										defaultMessage: 'Login'
									}),
									onButtonClick: handleLoginClick
								})
					}
				default:
					return {
						buttonText: intl.formatMessage({
							id: 'PreChargingView.ButtonTextBack',
							defaultMessage: 'Back'
						}),
						connectorStatusText: intl.formatMessage({
							id: 'PreChargingView.ConnectorStatusTextUnavailable',
							defaultMessage: 'Charger Unavailable'
						}),
						connectorStatusTextClassName: 'text-error-300',
						onButtonClick: handleBackClick
					}
			}
		}
		// Connector is still loading (or does not exist)
		return {}
	}, [
		connector,
		evse?.capabilities,
		historyKey,
		intl,
		isAuthenticated,
		isTransientFlow,
		isUserActiveChargingSessionElsewhere,
		intermediateModalContentRender,
		pathname,
		session?._id,
		handleNext,
		navigate,
		toggleIntermediateModal
	])

	/**
	 * Clear previous Transient Session ID if that session has already concluded or if payment was unsuccessful.
	 */
	useEffect((): void => {
		if (
			isTransientFlow &&
			!!transientSessionId &&
			((!!session?.status &&
				[
					OmniSessionStatus.Completed,
					OmniSessionStatus.StartFailure,
					OmniSessionStatus.StopFailure
				].includes(session.status)) ||
				paymentSuccess === 'false')
		) {
			console.debug(
				`[${ChargerScreenViewItemKey.PreChargingView}] Cleared previous Transient Session ID:`,
				{
					transientSessionId,
					paymentSuccess,
					sessionStatus: session?.status
				}
			)
			setTransientSessionId(null)
			setQueryParams(
				(queryParams): URLSearchParams => {
					if (queryParams.has('payment_success')) {
						queryParams.delete('payment_success')
					}
					return queryParams
				},
				{
					replace: true
				}
			)
		}
	}, [
		isTransientFlow,
		paymentSuccess,
		session?.status,
		transientSessionId,
		setQueryParams,
		setTransientSessionId
	])

	/**
	 * If the user has an active or finishing session on the charger,
	 * jump to the required view. This check should only execute once.
	 */
	useEffect((): void => {
		if (!!connector?.status && isChargerSessionDetailsSuccess) {
			// Session is active; jump to Charging View.
			if (isUserActiveChargingSession) {
				console.debug(
					`[${ChargerScreenViewItemKey.PreChargingView}] User has an active session on this charger`
				)
				updateView?.(ChargerScreenViewItemKey.ChargingView)
			} else if (isUserChargingSession) {
				if (
					session?.status &&
					[OmniSessionStatus.Pending, OmniSessionStatus.PendingPayment].includes(session.status)
				) {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has a pending session on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.ChargingView)
				}
				// Session is complete but connector is still in finishing; jump to Post-Charging View.
				else if (
					session?.status &&
					// Session has not failed
					![OmniSessionStatus.StartFailure, OmniSessionStatus.StopFailure].includes(
						session.status
					) &&
					connector.status === OmniConnectorStatus.Finishing
				) {
					console.debug(
						`[${ChargerScreenViewItemKey.PreChargingView}] User has a finishing session on this charger`
					)
					updateView?.(ChargerScreenViewItemKey.PostChargingView)
				}
			}
			// No active or finishing session.
			else {
				console.debug(
					`[${ChargerScreenViewItemKey.PreChargingView}] User does not have any active or finishing session on this charger`
				)
			}
		}
	}, [
		connector?.status,
		isChargerSessionDetailsSuccess,
		isUserActiveChargingSession,
		isUserChargingSession,
		session?.status,
		updateView
	])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.PreChargingView
	})

	// Loader (to hide view jumping)
	if (
		(isAuthenticated && !isChargerSessionDetailsSuccess) ||
		(isTransientFlow && !!transientSessionId)
	) {
		return (
			<div className="flex flex-grow flex-col items-center justify-center pb-6">
				<Spinner />
			</div>
		)
	}
	// Error Display
	// Note: Do not use `updateView` for this error because the Back Button
	// should return the user to the last screen prior to the Charger Screen.
	else if (!!locationError || !!connectorError) {
		const statusCode = locationError?.response?.status ?? connectorError?.response?.status
		return (
			<Notice
				type="error"
				header={
					<h1 className="text-center text-typography-primary">
						{statusCode === 404 ? (
							<FormattedMessage
								id="PreChargingView.TitleChargerNotFound"
								defaultMessage="Charger Not Found"
							/>
						) : (
							<FormattedMessage
								id="PreChargingView.TitleOopsFailure"
								defaultMessage="Oops! Something Went Wrong"
							/>
						)}
					</h1>
				}
			/>
		)
	}
	// Pre-Charging View
	return (
		<>
			{/* Header and Content Body */}
			<div className="flex-grow">
				<ChargingHeader
					className="mb-1"
					loading={isLocationPending}
					location={location}
					evse={evse}
					connector={connector}
					topRender={((): JSX.Element | null => {
						switch (BRAND) {
							case Brand.Kineta:
								return <StateOfChargeCircle className="mb-3" />
							case Brand.SpGroup: {
								// Show State of Charge only for DC Chargers
								if (connector?.power_type === OmniConnectorPowerType.Dc) {
									return <StateOfChargeCircle className="mb-3" />
								}
								return null
							}
							default:
								return null
						}
					})()}
					bottomRender={
						<Skeleton loading={isLocationPending}>
							<button data-testid="cs-pcv-btn-toggle-tariff" onClick={toggleTariffModal}>
								<span className="body-1-normal text-typography-primary underline">
									<FormattedMessage
										id="PreChargingView.ButtonTextFeeDetails"
										defaultMessage="Fee Details"
									/>
								</span>
							</button>
						</Skeleton>
					}
				/>
				<DetailsGrid location={location} itemList={gridItemList} loading={isLocationPending} />
			</div>
			{/* Footer */}
			<Footer
				className="space-y-6"
				buttonProps={{
					children: footerRenders.buttonText,
					className: 'w-full',
					disabled:
						isLocationPending || isStartChargingSessionLoading || !footerRenders.onButtonClick,
					loading: isStartChargingSessionLoading,
					onClick: footerRenders.onButtonClick
				}}
				topRender={
					<Skeleton className="h-5 w-36" loading={isLocationPending}>
						<h1
							className={classNames(
								'max-w-64 text-center',
								footerRenders.connectorStatusTextClassName
							)}
						>
							{footerRenders.connectorStatusText}
						</h1>
					</Skeleton>
				}
				bottomRender={((): JSX.Element | null => {
					if ((isAuthenticated || isTransientFlow) && footerRenders.isButtonTextNext) {
						return (
							<TermsAndConditionsBottomRender
								prefixRender={
									<FormattedMessage
										id="PreChargingView.FooterTextTermsAndConditionsPrefix"
										defaultMessage='By clicking "Next", you agree to our '
									/>
								}
							/>
						)
					}
					return null
				})()}
			/>
			<PoweredByFooter className="pb-6" />
			{/* Intermediate Modal */}
			<Modal open={isIntermediateModalOpen} onClose={toggleIntermediateModal}>
				<ModalCard className="flex flex-col items-center">
					{intermediateModalContentRender}
					<div className="flex w-full flex-col space-y-4">
						<Button
							data-testid="cs-pcv-btn-next"
							variant="primary"
							loading={isStartChargingSessionPending || isStartChargingSessionSuccess}
							disabled={isStartChargingSessionPending || isStartChargingSessionSuccess}
							onClick={handleNext}
						>
							<FormattedMessage
								id="PreChargingView.ModalButtonTextUnderstand"
								defaultMessage="I Understand"
							/>
						</Button>
						<Button
							data-testid="cs-pcv-btn-cancel"
							variant="secondary"
							disabled={isStartChargingSessionPending || isStartChargingSessionSuccess}
							onClick={toggleIntermediateModal}
						>
							<FormattedMessage
								id="PreChargingView.ModalButtonTextCancel"
								defaultMessage="Cancel"
							/>
						</Button>
					</div>
				</ModalCard>
			</Modal>
			{/* Tariff Modal */}
			<TariffModal
				location={location}
				activeTariff={activeTariff}
				open={isTariffModalOpen}
				itemList={tariffInformationItemList}
				onClose={toggleTariffModal}
			/>
		</>
	)
}

export default PreChargingView
