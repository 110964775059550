import { DetailedHTMLProps, HTMLAttributes, memo, MouseEvent, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import BookmarkFilledIcon from 'src/_shared/components/_icons/BookmarkFilledIcon'
import BookmarkIcon from 'src/_shared/components/_icons/BookmarkIcon'
import BottomScanQrIcon from 'src/_shared/components/_icons/BottomBarScanQrIcon'
import ListIcon from 'src/_shared/components/_icons/ListIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import { useUserZoomCoordinates } from 'src/_shared/hooks/useUserZoomCoordinates'
import { classNames } from 'src/_shared/utils/elements'

import AccountFilledIcon from '../../_icons/AccountFilledIcon'
import AccountIcon from '../../_icons/AccountIcon'
import LocationPinFilledIcon from '../../_icons/LocationPinFilledIcon'
import LocationPinIcon from '../../_icons/LocationPinIcon'

interface NavigationItem {
	title: string
	pathname:
		| ScreenRoutePath.Map
		| ScreenRoutePath.Locations
		| ScreenRoutePath.Favourites
		| ScreenRoutePath.Account
	defaultIcon: JSX.Element
	selectedIcon: JSX.Element
}

const NAVIGATION_ITEMS: NavigationItem[] = [
	{
		title: 'Maps',
		pathname: ScreenRoutePath.Map,
		defaultIcon: <LocationPinIcon className="h-6 w-6 text-typography-tertiary/75" />,
		selectedIcon: <LocationPinFilledIcon className="h-6 w-6 text-primary-800" />
	},
	{
		title: 'List',
		pathname: ScreenRoutePath.Locations,
		defaultIcon: <ListIcon className="h-6 w-6 text-typography-tertiary/75" />,
		selectedIcon: <ListIcon className="h-6 w-6 text-primary-800" />
	},
	{
		title: 'Favourites',
		pathname: ScreenRoutePath.Favourites,
		defaultIcon: <BookmarkIcon className="h-6 w-6 text-typography-tertiary/75" />,
		selectedIcon: <BookmarkFilledIcon className="h-6 w-6 text-primary-800" />
	},
	{
		title: 'Account',
		pathname: ScreenRoutePath.Account,
		defaultIcon: <AccountIcon className="h-6 w-6 text-typography-tertiary/75" />,
		selectedIcon: <AccountFilledIcon className="h-6 w-6 text-primary-800" />
	}
]

type NavigationItemProps = NavigationItem & {
	handleLinkClick: (pathname: ScreenRoutePath) => void
}

const Item = ({
	title,
	pathname,
	defaultIcon,
	selectedIcon,
	handleLinkClick
}: NavigationItemProps): JSX.Element => {
	const location = useRouterLocation()

	const isSelected = location.pathname.includes(pathname)

	const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault() // handle the redirection manually
		handleLinkClick(pathname)
	}

	return (
		<Link
			onClick={handleClick}
			to={pathname}
			className="flex cursor-pointer flex-col items-center justify-end no-underline"
		>
			<div className="flex flex-col items-center justify-center gap-0.5">
				{isSelected ? selectedIcon : defaultIcon}
				<span
					className={classNames(
						'caption-3-normal',
						isSelected ? 'text-typography-primary' : 'text-typography-tertiary/75'
					)}
				>
					{title}
				</span>
			</div>
		</Link>
	)
}

export type BottomBarProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'children' | 'ref'
>

const BottomBar = ({ className, style, ...props }: BottomBarProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const { userZoomCoordinates } = useUserZoomCoordinates()

	const handleLinkClick = useCallback(
		(pathname: ScreenRoutePath): void => {
			if (pathname === ScreenRoutePath.Map && userZoomCoordinates?.zoomCoordinates) {
				const { zoomCoordinates, zoom = DEFAULT_ZOOM } = userZoomCoordinates
				const { lat, lng } = zoomCoordinates
				navigate({
					pathname: ScreenRoutePath.Map,
					search: `lat=${lat}&lng=${lng}&zoom=${zoom}`
				})
			} else {
				navigate(pathname)
			}
		},
		[navigate, userZoomCoordinates]
	)

	const handleScanQrClick = useCallback((): void => {
		navigate(ScreenRoutePath.CheckIn)
	}, [navigate])

	return (
		<div
			className={classNames(
				'sticky bottom-0 z-50 flex justify-around bg-white px-2 pt-3 shadow-[0_0_20px_7px_rgba(0,0,0,0.04)] 2xs:px-5',
				className
			)}
			style={{
				...style,
				paddingBottom: style?.paddingBottom ?? 'calc(12px + env(safe-area-inset-bottom))'
			}}
			{...props}
		>
			<div className="flex flex-1 justify-around">
				{NAVIGATION_ITEMS.slice(0, 2).map(({ ...item }, index): JSX.Element => {
					return <Item key={index} handleLinkClick={handleLinkClick} {...item} />
				})}
			</div>
			<div className="flex w-16 justify-center">
				<Button
					className="absolute bottom-9 h-16 w-16 rounded-full shadow-[0_0_20px_7px_rgba(0,0,0,0.04)]"
					onClick={handleScanQrClick}
				>
					<BottomScanQrIcon className="mt-1 h-16 w-16" fill="white" />
				</Button>
			</div>
			<div className="flex flex-1 justify-around">
				{NAVIGATION_ITEMS.slice(2, 4).map(({ ...item }, index): JSX.Element => {
					return <Item key={index} handleLinkClick={handleLinkClick} {...item} />
				})}
			</div>
		</div>
	)
}

const MemoisedBottomBar = memo(BottomBar)

export default MemoisedBottomBar
