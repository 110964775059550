import { createContext, useContext } from 'react'
import { LocationFilterModalType } from 'src/_shared/enums/filter'

interface LocationFiltersModalContextData {
	showModal: (modalType: LocationFilterModalType) => void
	hideModal: () => void
}

export const LocationFiltersModalContext = createContext<LocationFiltersModalContextData>({
	showModal: (): void => {
		console.debug('[LocationFiltersModalContextWrapper] showModal was not provided.')
	},
	hideModal: (): void => {
		console.debug('[LocationFiltersModalContextWrapper] hideModal was not provided.')
	}
})

export const useLocationFiltersModalContext = (): LocationFiltersModalContextData => {
	const context = useContext(LocationFiltersModalContext)
	return context
}
