import { SVGProps, memo } from 'react'

const ChevronUpIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeWidth = 2,
	viewBox = '0 0 14 9',
	xmlns = 'http://www.w3.org/2000/svg',
	strokeLinecap = 'round',
	strokeLinejoin = 'round',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			strokeLinecap={strokeLinecap}
			strokeLinejoin={strokeLinejoin}
			{...props}
		>
			<path d="M13 7.21155L7 1.5577L1 7.21155" />
		</svg>
	)
}

const MemoisedChevronUpIcon = memo(ChevronUpIcon)

export default MemoisedChevronUpIcon
