import { Coordinates } from 'src/_shared/types/location'

const EARTH_RADIUS = 6371000 // Metres

const convertDegreesToRadians = (degrees: number): number => {
	return degrees * (Math.PI / 180)
}

/**
 * Calculates the distance between two points using the Haversine formula.
 * @param {Coordinates} a Point A.
 * @param {Coordinates} b Point B.
 * @returns {number} The distance between Point A and Point B in metres.
 * @see https://www.movable-type.co.uk/scripts/latlong.html
 */
export const getDistanceBetweenCoordinates = (a: Coordinates, b: Coordinates): number => {
	const latDiff = convertDegreesToRadians(b.latitude - a.latitude)
	const lngDiff = convertDegreesToRadians(b.longitude - a.longitude)
	const latARad = convertDegreesToRadians(a.latitude)
	const latBRad = convertDegreesToRadians(b.latitude)
	const halfChordLengthSquared =
		Math.pow(Math.sin(latDiff / 2), 2) +
		Math.cos(latARad) * Math.cos(latBRad) * Math.pow(Math.sin(lngDiff / 2), 2)
	const angularDistanceRads =
		2 * Math.atan2(Math.sqrt(halfChordLengthSquared), Math.sqrt(1 - halfChordLengthSquared))
	const distance = EARTH_RADIUS * angularDistanceRads
	return distance
}
