import { memo } from 'react'
import { OmniLocation } from 'src/_shared/types/omni/location'

import Button from '../Button'
import Modal from '../Modal'
import ModalCard from '../Modal/components/ModalCard'
import LetterIcon from '../_icons/LetterIcon'

interface CarparkRatesModalButtonProps {
	className?: string
	location: OmniLocation | null
	open: boolean
	onClose: () => void
}

const CarparkRatesModal = ({
	className,
	location,
	open,
	onClose: handleClose
}: CarparkRatesModalButtonProps): JSX.Element => {
	return (
		<Modal className={className} open={open} onClose={handleClose}>
			<ModalCard>
				<div className="mb-6 flex items-center space-x-1.5">
					<LetterIcon value="P" />
					<h1>Car Park Rates</h1>
				</div>
				<div className="mb-4">
					<p className="body-2-bold mb-1">{location?.name ?? ''}</p>
					<p className="caption-3-normal block items-center">{location?.address ?? ''}</p>
				</div>
				<div className="mb-4 border-b border-divider-primary" />
				{/* TODO: Display Car Park Rates based on `location`; best to have API support for this somehow */}
				<p className="body-2-normal mb-6 text-typography-secondary">
					Car park rates for this location coming soon!
				</p>
				<Button className="w-full" onClick={handleClose}>
					Close
				</Button>
			</ModalCard>
		</Modal>
	)
}

const MemoisedCarparkRatesModal = memo(CarparkRatesModal)

export default MemoisedCarparkRatesModal
