import { SVGProps, memo } from 'react'

const BatteryFilledIcon = ({
	fill = 'currentColor',
	stroke = 'none',
	strokeWidth = '0',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path d="M9 4V3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V4H18C18.5523 4 19 4.44772 19 5V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V5C5 4.44772 5.44772 4 6 4H9ZM13 12V7L8 14H11V19L16 12H13Z" />
		</svg>
	)
}

const MemoisedBatteryFilledIcon = memo(BatteryFilledIcon)

export default MemoisedBatteryFilledIcon
