import { useCallback, useMemo } from 'react'
import { useSessionStorageItem } from 'src/_shared/hooks/useStorageItem'
import { PlaceSuggestion } from 'src/_shared/types/google'

const SELECTED_PLACE_SUGGESTION_KEY = 'selectedPlaceSuggestion'

const useSelectedPlaceSuggestion = (): [
	PlaceSuggestion | null,
	(value: PlaceSuggestion | null) => void
] => {
	const [selectedPlaceSuggestionObj, setSelectedPlaceSuggestionObj] = useSessionStorageItem(
		SELECTED_PLACE_SUGGESTION_KEY
	)

	const setSelectedPlaceSuggestion = useCallback(
		(value: PlaceSuggestion | null): void => {
			setSelectedPlaceSuggestionObj(value ? JSON.stringify(value) : null)
		},
		[setSelectedPlaceSuggestionObj]
	)

	const placeSuggestion = useMemo((): PlaceSuggestion | null => {
		if (selectedPlaceSuggestionObj) {
			try {
				const parsedPlaceSuggestion: unknown = JSON.parse(selectedPlaceSuggestionObj)
				if (typeof parsedPlaceSuggestion === 'object' && parsedPlaceSuggestion !== null) {
					return parsedPlaceSuggestion as PlaceSuggestion
				}
			} catch (error) {
				console.error('[useSelectedPlaceSuggestion]', error)
			}
		}
		return null
	}, [selectedPlaceSuggestionObj])

	return [placeSuggestion, setSelectedPlaceSuggestion]
}

export default useSelectedPlaceSuggestion
