import { SVGProps, memo } from 'react'

const LocationDetailsPinIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 16 16',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				id="Vector"
				opacity="0.25"
				d="M13.3877 6.06152C12.7314 3.17402 10.2127 1.87402 8.00018 1.87402C8.00018 1.87402 8.00018 1.87402 7.99393 1.87402C5.78768 1.87402 3.26268 3.16777 2.60643 6.05527C1.87518 9.28027 3.85018 12.0115 5.63768 13.7303C6.30018 14.3678 7.15018 14.6865 8.00018 14.6865C8.85018 14.6865 9.70018 14.3678 10.3564 13.7303C12.1439 12.0115 14.1189 9.28652 13.3877 6.06152Z"
			/>
			<path
				id="Vector_2"
				d="M8 9.19238C9.08731 9.19238 9.96875 8.31094 9.96875 7.22363C9.96875 6.13632 9.08731 5.25488 8 5.25488C6.91269 5.25488 6.03125 6.13632 6.03125 7.22363C6.03125 8.31094 6.91269 9.19238 8 9.19238Z"
			/>
		</svg>
	)
}

const MemoisedLocationDetailsPinIcon = memo(LocationDetailsPinIcon)

export default MemoisedLocationDetailsPinIcon
