import { PropsWithChildren, useCallback, useState } from 'react'
import { LocationFilterModalType } from 'src/_shared/enums/filter'
import { LocationFiltersModalContext } from 'src/_shared/hooks/useLocationFiltersModalContext'

import LocationFilterModal from './components/LocationFilterModal'

/**
 * Provides context for managing the state of the location filters modal.
 */
const LocationFiltersModalWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const [modalType, setModalType] = useState<LocationFilterModalType | null>(null)

	const showModal = useCallback(
		(filterModalType: LocationFilterModalType): void => {
			setModalType(filterModalType)
		},
		[setModalType]
	)

	const hideModal = useCallback((): void => {
		setModalType(null)
	}, [setModalType])

	return (
		<LocationFiltersModalContext.Provider value={{ showModal, hideModal }}>
			{children}
			{!!modalType && <LocationFilterModal open={!!modalType} filterModalType={modalType} />}
		</LocationFiltersModalContext.Provider>
	)
}

export default LocationFiltersModalWrapper
