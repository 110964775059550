import { useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import Spinner from 'src/_shared/components/Spinner'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import TrashIcon from 'src/_shared/components/_icons/TrashIcon'
import { DEFAULT_ZOOM } from 'src/_shared/constants/map'
import { OmniCountryCode } from 'src/_shared/enums/omni'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import { useUserZoomCoordinates } from 'src/_shared/hooks/useUserZoomCoordinates'
import { useRemoveUserFavouriteLocationMutation } from 'src/_shared/mutations/user'
import { useReverseGeocodingQuery } from 'src/_shared/queries/google'
import {
	FavouriteLocationsPage,
	ROOT_USER_QUERY_KEY,
	UserQueryKey,
	useUserFavouriteLocationsInfiniteQuery
} from 'src/_shared/queries/user'
import { OmniLocation, OmniLocationNearby } from 'src/_shared/types/omni/location'
import { classNames } from 'src/_shared/utils/elements'
import FavouriteLocationListController from 'src/screens/FavouritesScreen/components/FavouriteLocationListController'
import { getUserCountry } from 'src/screens/FavouritesScreen/utils'

import SelectFavouritesCountry from './components/SelectFavouritesCountry'

const FavouritesScreen = (): JSX.Element => {
	const [isEditing, setIsEditing] = useState(false)

	const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false)

	const [toggledLocations, setToggledLocations] = useState<string[]>([])

	// This state is used to indicate that the user is currently deleting favourites,
	// and to disable the UI while the deletion is in progress and to defer till everything is refetched.
	const [isDeletingFavourites, setIsDeletingFavourites] = useState(false)

	const { user } = useAuthContext()

	const { coordinates } = useUserCoordinates()

	const { data: currentLocationCountryCode, isLoading: isReverseGeocodingLoading } =
		useReverseGeocodingQuery(
			{
				latitude: coordinates?.latitude ?? 0,
				longitude: coordinates?.longitude ?? 0
			},
			{
				enabled: !!coordinates
			}
		)

	const userCountryCode = useMemo((): OmniCountryCode => {
		return getUserCountry(currentLocationCountryCode, user?.mobileNumber)
	}, [currentLocationCountryCode, user?.mobileNumber])

	const [country, setCountry] = useState<OmniCountryCode | ''>('')

	const { userZoomCoordinates } = useUserZoomCoordinates()

	const navigate = useRouterNavigate()

	const queryClient = useQueryClient()

	const { mutate: removeFavouriteLocation } = useRemoveUserFavouriteLocationMutation({
		onMutate: (): void => {
			setIsDeletingFavourites(true)
		},
		onError: (): void => {
			setShowDeleteAlertModal(false)
			setIsDeletingFavourites(false)
			setToggledLocations([])
			setIsEditing(false)
		},
		onSuccess: async (): Promise<void> => {
			await queryClient.invalidateQueries({
				queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserFavouritesLocationSummary]
			})

			await queryClient.invalidateQueries({
				queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserFavouritesInfiniteLocations]
			})

			setShowDeleteAlertModal(false)
			setIsDeletingFavourites(false)
			setToggledLocations([])
			setIsEditing(false)
		}
	})

	const {
		data: favouiteLocationsData = {
			pages: []
		},
		isLoading: isLocationsLoading,
		fetchNextPage,
		hasNextPage
	} = useUserFavouriteLocationsInfiniteQuery({
		country_code: country === '' ? undefined : country
	})

	const locations = useMemo((): OmniLocation[] => {
		const newData: OmniLocation[] = favouiteLocationsData.pages.reduce(
			(acc: OmniLocation[], favouriteLocationPage: FavouriteLocationsPage): OmniLocation[] => {
				return [...acc, ...favouriteLocationPage.data]
			},
			[]
		)
		return newData
	}, [favouiteLocationsData])

	const handleBackClick = useCallback((): void => {
		if (userZoomCoordinates?.zoomCoordinates) {
			const { zoomCoordinates, zoom = DEFAULT_ZOOM } = userZoomCoordinates
			const { lat, lng } = zoomCoordinates
			navigate({
				pathname: ScreenRoutePath.Map,
				search: `lat=${lat}&lng=${lng}&zoom=${zoom}`
			})
		} else {
			navigate(ScreenRoutePath.Map)
		}
	}, [userZoomCoordinates, navigate])

	const handleToggleLocation = useCallback(
		(selectedLocation: string): void => {
			if (isDeletingFavourites) {
				return
			}
			setToggledLocations((prev): string[] => {
				if (prev.some((location): boolean => location === selectedLocation)) {
					return prev.filter((location): boolean => location !== selectedLocation)
				}
				return [...prev, selectedLocation]
			})
		},
		[isDeletingFavourites]
	)

	const handleSelectChange = useCallback((event: ChangeEvent<HTMLSelectElement>): void => {
		const selectedValue = event.currentTarget.value
		setCountry(selectedValue as OmniCountryCode)
	}, [])

	const handleEditButtonClick = useCallback((): void => {
		if (isDeletingFavourites) {
			return
		}
		if (!isEditing) {
			setIsEditing(true)
		} else {
			const filteredLocations = toggledLocations.filter((location): boolean => location.length > 0)
			if (filteredLocations.length > 0) {
				setShowDeleteAlertModal(true)
			} else {
				setIsEditing(false)
			}
		}
	}, [isEditing, isDeletingFavourites, toggledLocations])

	const handleShowDeleteAlertModalClose = useCallback((): void => {
		setShowDeleteAlertModal(false)
	}, [])

	const handleDeleteFavouriteLocation = useCallback((): void => {
		const filteredLocations = toggledLocations.filter((location): boolean => location.length > 0)
		removeFavouriteLocation({
			locationUids: filteredLocations
		})
	}, [removeFavouriteLocation, toggledLocations])

	useEffect((): void => {
		if (!isReverseGeocodingLoading) {
			setCountry(userCountryCode)
		}
	}, [isReverseGeocodingLoading, userCountryCode])

	return (
		<>
			<ScreenContainer
				topBarProps={{
					centerRender: <h1>Favourites</h1>,
					leftRender: (
						<TopBarButton data-testid="fs-btn-back" onClick={handleBackClick}>
							<ArrowLeftIcon className="h-4" />
						</TopBarButton>
					),
					className: 'shadow-none',
					rightRender: (
						<button onClick={handleEditButtonClick}>
							{isEditing ? (
								<CheckIcon data-testid="fs-icon-tb-check" className="h-4" />
							) : (
								<TrashIcon data-testid="fs-icon-tb-trash" className="h-4" />
							)}
						</button>
					)
				}}
			>
				{isReverseGeocodingLoading && (
					<div className="flex flex-grow items-center justify-center">
						<Spinner />
					</div>
				)}
				{!isReverseGeocodingLoading && (
					<>
						<div className="my-3 px-2.5">
							<SelectFavouritesCountry value={country} onChange={handleSelectChange} />
						</div>
						<div className="flex items-center justify-between px-4 py-3 shadow-md">
							<span className="caption-2-semibold text-typography-primary">Charging Location</span>
							<span className="caption-2-semibold text-typography-secondary">
								Available / Total
							</span>
						</div>
						<div
							className={classNames(
								'flex flex-grow',
								isLocationsLoading ? 'items-center justify-center' : null
							)}
						>
							{isLocationsLoading ? (
								<Spinner />
							) : (
								<FavouriteLocationListController
									isEditing={isEditing}
									toggledLocations={toggledLocations}
									onToggleLocation={handleToggleLocation}
									locations={locations.map((location): OmniLocationNearby => ({ location }))}
									onFetchNextPageCallback={hasNextPage ? fetchNextPage : undefined}
								/>
							)}
						</div>
					</>
				)}
			</ScreenContainer>
			<Modal open={showDeleteAlertModal} onClose={handleShowDeleteAlertModalClose}>
				<ModalCard className="flex flex-col items-center">
					<h1 className="mb-2 text-center">Notice</h1>
					<p
						data-testid="fs-text-alert-remove-favourites"
						className="body-2-normal mb-8 text-center"
					>
						Are you sure you want to delete these favourites?
					</p>
					<div className="flex w-full flex-col space-y-4">
						<Button
							data-testid="fs-btn-confirm-remove-favourites"
							variant="primary"
							disabled={isDeletingFavourites}
							loading={isDeletingFavourites}
							onClick={handleDeleteFavouriteLocation}
						>
							Confirm
						</Button>
						<Button
							data-testid="fs-btn-cancel-remove-favourites"
							variant="secondary"
							disabled={isDeletingFavourites}
							onClick={handleShowDeleteAlertModalClose}
						>
							Cancel
						</Button>
					</div>
				</ModalCard>
			</Modal>
		</>
	)
}

export default FavouritesScreen
