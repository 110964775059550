import { DetailedHTMLProps, SelectHTMLAttributes, memo, useMemo } from 'react'
import { classNames } from 'src/_shared/utils/elements'
import { formatDataTestId } from 'src/_shared/utils/string'

import ChevronDownIcon from '../_icons/ChevronDownIcon'

export interface SelectOption<T> {
	label: string
	dropdownLabel?: string
	value: T
	icon?: React.ReactNode
}

export type SelectProps<T> = {
	options: SelectOption<T>[]
	dataTestIdPrefix?: string
	icon?: React.ReactNode
	mainClassName?: string
} & Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'children'>

const Select = <T extends number | string>({
	value,
	options,
	disabled,
	dataTestIdPrefix = '',
	icon,
	mainClassName = '',
	...selectProps
}: SelectProps<T>): JSX.Element => {
	const selectedOption = useMemo((): SelectOption<T> | null => {
		const option = options.find((option): boolean => {
			return option.value === value
		})
		return option ?? null
	}, [options, value])

	return (
		<div
			className={classNames('relative', mainClassName)}
			data-testid={formatDataTestId([dataTestIdPrefix, 'select-box'])}
		>
			{/* Hidden Select */}
			{/* This will get clicked on and show the default select dropdown. */}
			<select
				data-testid={formatDataTestId([dataTestIdPrefix, 'select'])}
				{...selectProps}
				className={classNames(
					// Base Classes
					'absolute z-10 h-full w-full opacity-0',
					// `disabled` Classes
					disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}
				value={selectedOption?.value}
				disabled={disabled}
			>
				{options.map(({ dropdownLabel, label, value }, index): JSX.Element => {
					return (
						<option
							data-testid={formatDataTestId([dataTestIdPrefix, 'select-option', value.toString()])}
							key={index}
							label={dropdownLabel ?? label}
							value={value}
						/>
					)
				})}
			</select>
			{/* Select Display */}
			<div
				className={classNames(
					// Base Classes
					'flex h-full flex-row items-center justify-between space-x-1.5',
					selectProps.className
				)}
			>
				<p
					data-testid={formatDataTestId([dataTestIdPrefix, 'label-select'])}
					className={classNames(
						// Base Classes
						'text-nowrap',
						// `disabled` Classes
						disabled ? 'text-typography-tertiary' : 'text-typography-primary'
					)}
				>
					{selectedOption?.label}
				</p>
				{icon ?? <ChevronDownIcon className="mt-0.5 w-3 text-primary-800" />}
			</div>
		</div>
	)
}

const MemoisedSelect = memo(Select)

export default MemoisedSelect
