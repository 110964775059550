import { useMemo } from 'react'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

/**
 * Determines if the user is subscribed to the CPO. If the user is subscribed, then they should not
 * be subjected to energy and idling (for now) costs .
 * @param {string} cpoEntityCode The CPO's entity code.
 * @returns {boolean} If `true`, then the user is subscribed to the CPO. Otherwise, returns `false`.
 */
export const useIsUserSubscribed = (cpoEntityCode: string): boolean => {
	const { user } = useAuthContext()

	const isUserSubscribed = useMemo((): boolean => {
		return (user?.subscribedCpoEntities ?? []).includes(cpoEntityCode)
	}, [cpoEntityCode, user?.subscribedCpoEntities])

	return isUserSubscribed
}
