export const formatDistanceDisplay = (metres?: number): string | null => {
	if (typeof metres === 'number') {
		if (metres >= 1000) {
			const kilometres = metres / 1000
			if (kilometres >= 100) {
				return `${Math.round(kilometres)}km`
			}
			return `${kilometres.toFixed(1)}km` // Show up to 1 d.p.
		}
		return `${Math.round(metres)}m`
	}
	return null
}
