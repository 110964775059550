import { SVGProps, memo } from 'react'

const BookmarkFilledIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 15 19',
	xmlns = 'http://www.w3.org/2000/svg',
	stroke = 'currentColor',
	strokeWidth = '2',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path d="M1.85174 2.03533L1.85175 2.03531C2.1945 1.69256 2.65937 1.5 3.14411 1.5H10.856C11.3407 1.5 11.8056 1.69256 12.1483 2.0353L12.1484 2.03537C12.4911 2.37809 12.6837 2.84292 12.6837 3.32768V17.2903L7.57206 13.7256L7.00005 13.3267L6.42804 13.7256L1.31643 17.2903V3.32768C1.31643 2.84294 1.50899 2.37807 1.85174 2.03533Z" />
		</svg>
	)
}

const MemoisedBookmarkFilledIcon = memo(BookmarkFilledIcon)

export default MemoisedBookmarkFilledIcon
