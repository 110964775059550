import { useEffect } from 'react'
import { useSessionStorage } from 'src/_shared/hooks/useStorageItem'

import { Coordinates } from '../types/location'

const WATCH_POSITION_TIMEOUT = 5000 // 5 Seconds

const USER_COORDINATES_KEY = 'userCoordinates'

interface UserCoordinates {
	coordinates: Coordinates | null
	/**
	 * If `null`, then user has yet to allow or block the permission request.
	 */
	isPermissionGranted: boolean | null
}

/**
 * Requests for location permissions and watch the user's position if permission is granted.
 * The permission request prompt is only done once. Should the user denies permissions, they
 * have to manually enable it themselves.
 */
export const useUserCoordinates = (): UserCoordinates => {
	// The user coordinates are stored in the session storage. It is updated whenever the user's position changes.
	// If it's not null, the coordnates can be safely used.
	const [userCoordinates, setUserCoordinates] =
		useSessionStorage<UserCoordinates>(USER_COORDINATES_KEY)

	useEffect((): (() => void) => {
		const watchId = navigator.geolocation.watchPosition(
			(position): void => {
				setUserCoordinates({
					coordinates: {
						// Note: Directly access both `latitude` and `longitude` to ensure that the
						// numeric coordinates provided are saved directly into session storage, regardless
						// of browser implementation of `GeolocationCoordinates`. Rounded to 3 d.p. to reduce
						// excessive re-rendering.
						latitude: Number(position.coords.latitude.toFixed(3)),
						longitude: Number(position.coords.longitude.toFixed(3))
					},
					isPermissionGranted: true
				})
			},
			(error): void => {
				if (error.code === 3) {
					// TODO: handle timeout error
					console.error('[useUserCoordinates] Position acquisition timed out')
				} else {
					// PERMISSION_DENIED or POSITION_UNAVAILABLE
					// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
					setUserCoordinates({
						coordinates: userCoordinates?.coordinates ?? null,
						isPermissionGranted: false
					})
					console.error('[useUserCoordinates]', error)
				}

				if (userCoordinates?.isPermissionGranted === null) {
					setUserCoordinates({ ...userCoordinates, isPermissionGranted: false })
				}
			},
			{
				maximumAge: 0,
				timeout: WATCH_POSITION_TIMEOUT
			}
		)
		return (): void => {
			navigator.geolocation.clearWatch(watchId)
		}
	}, [setUserCoordinates, userCoordinates])

	return {
		coordinates: userCoordinates?.coordinates ?? null,
		isPermissionGranted: userCoordinates?.isPermissionGranted ?? null
	}
}
