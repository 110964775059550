import { memo } from 'react'
import SearchIcon from 'src/_shared/components/_icons/SearchIcon'

import { GoogleMapMarkerProps } from '../types'

type SearchPositionMarkerProps = GoogleMapMarkerProps

const SearchPositionMarker = ({ lat, lng }: SearchPositionMarkerProps): JSX.Element => {
	return (
		// Transformation done to reflect the true center of the coordinates (where the triangle is pointing at)
		<div
			id={`search-position-${[lat, lng].join(',')}`}
			// Account for square height in y-translation
			className="absolute -translate-x-1/2 -translate-y-[calc(50%+16px)] transform"
		>
			<div className="animate-fade-in flex flex-col items-center drop-shadow-md">
				{/* Square */}
				<div className="flex h-8 w-8 items-center justify-center rounded-lg bg-info-400">
					<SearchIcon className="h-4 w-4 text-grayscale-100" strokeWidth={3} />
				</div>
				{/* Down Triangle */}
				<div className="mt-[-1px] h-0 w-0 border-x-[6px] border-t-[10px] border-info-400 border-x-transparent" />
			</div>
		</div>
	)
}

const MemoisedSearchPositionMarker = memo(SearchPositionMarker)

export default MemoisedSearchPositionMarker
