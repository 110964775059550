import { QueryStatus, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import useDebounce from 'src/_shared/hooks/useDebounce'
import { useLocationFilters } from 'src/_shared/hooks/useLocationFilters'
import { useUserCoordinates } from 'src/_shared/hooks/useUserCoordinates'
import {
	LocationsQueryKey,
	ROOT_LOCATIONS_QUERY_KEY,
	useLocationsNearbyQuery,
	useLocationsQuery
} from 'src/_shared/queries/locations'
import { Coordinates } from 'src/_shared/types/location'
import { OmniLocation } from 'src/_shared/types/omni/location'
import {
	filterConnectorsWithLocationFilters,
	filterLocationWithLocationFilters
} from 'src/_shared/utils/filter'
import { getDistanceBetweenCoordinates } from 'src/_shared/utils/location'
import Supercluster, { ClusterProperties, PointFeature } from 'supercluster'
import useSupercluster from 'use-supercluster'

import { MAX_ZOOM } from './constants'
import { LocationDetails } from './types'
import { mergeLocationsData } from './utils'

/**
 * Based on the width of the `LocationsClusterMarker`.
 */
const CLUSTER_RADIUS = 64 * 2

const DEFAULT_REFETCH_INTERVAL = 60000 // 1 Minute

const QUERY_PARAMS_DEBOUNCE_DELAY = 500 // 0.5 Seconds

const QUERY_RADIUS_MAX = 50000 // Metres

interface UseLocationClustersArgs {
	coordinates: {
		center: google.maps.LatLng
		northEast: google.maps.LatLng
		southWest: google.maps.LatLng
	} | null
	/**
	 * If `true`, fetch and parse `locations` into `clusters` and `supercluster`.
	 */
	enabled: boolean
	zoom: number
}

interface LocationClusters {
	locationsNearbyQueryStatus: QueryStatus
	pointFeatures: (PointFeature<LocationDetails> | PointFeature<ClusterProperties>)[]
	supercluster?: Supercluster<LocationDetails>
}

/**
 * If `enabled`, handles the fetching, sanitising, and parsing of Charger Locations
 * into point features which are to be rendered accordingly in the Map.
 */
export const useLocationClusters = ({
	coordinates,
	enabled: isMapLoaded,
	zoom
}: UseLocationClustersArgs): LocationClusters => {
	const queryClient = useQueryClient()

	const { locationFilters } = useLocationFilters()

	const { coordinates: userCoordinates } = useUserCoordinates()

	const { user } = useAuthContext()

	const { data: locations = [] } = useLocationsQuery(
		{
			fetchAll: true,
			publish: true
		},
		{
			staleTime: Infinity
		}
	)

	const [centerCoordinates, northEastCoordinates] = useMemo((): [Coordinates, Coordinates] => {
		if (coordinates) {
			return [
				{
					latitude: coordinates.center.lat(),
					longitude: coordinates.center.lng()
				},
				{
					latitude: coordinates.northEast.lat(),
					longitude: coordinates.northEast.lng()
				}
			]
		}

		const zeroCoordinate: Coordinates = {
			latitude: 0,
			longitude: 0
		}

		return [zeroCoordinate, zeroCoordinate]
	}, [coordinates])

	/**
	 * Debounced to prevent `useLocationsNearbyQuery` from re-executing excessively.
	 */
	const locationsNearbyQueryParams = useDebounce(
		{
			// Use up to 6 d.p. for sufficient accuracy
			latitude: Number(centerCoordinates.latitude.toFixed(6)),
			longitude: Number(centerCoordinates.longitude.toFixed(6)),
			radius: Math.min(
				Math.round(
					getDistanceBetweenCoordinates(centerCoordinates, northEastCoordinates) *
						// Account for locations slightly beyond the viewing bounds
						Math.max(zoom / 8, 1)
				),
				QUERY_RADIUS_MAX
			),
			fetchAll: true
		},
		QUERY_PARAMS_DEBOUNCE_DELAY
	)

	const { data: locationsNearby = [], status: locationsNearbyQueryStatus } =
		useLocationsNearbyQuery<OmniLocation[]>(locationsNearbyQueryParams, {
			enabled: isMapLoaded && locationsNearbyQueryParams.radius > 0,
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			staleTime: Infinity,
			select: (data): OmniLocation[] => {
				return data.map(({ location = {} }): OmniLocation => {
					return location
				})
			}
		})

	const points = useMemo((): PointFeature<LocationDetails>[] => {
		const filteredLocations = locations.filter((location): boolean => {
			return filterLocationWithLocationFilters(
				location,
				userCoordinates,
				user?.subscribedCpoEntities ?? [],
				locationFilters
			)
		})

		return filteredLocations.reduce<PointFeature<LocationDetails>[]>(
			(formattedLocations, location): PointFeature<LocationDetails>[] => {
				const locationUid = location.uid ? location.uid : null

				const longitude = location.coordinates?.longitude
					? Number(location.coordinates.longitude)
					: null

				const latitude = location.coordinates?.latitude
					? Number(location.coordinates.latitude)
					: null

				if (!!locationUid && longitude !== null && latitude !== null) {
					const hasAvailableConnector = filterConnectorsWithLocationFilters(
						location,
						locationFilters
					)

					return formattedLocations.concat({
						id: locationUid,
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [longitude, latitude]
						},
						properties: {
							cluster: false,
							location,
							hasAvailableConnector
						}
					})
				}

				return formattedLocations
			},
			[]
		)
	}, [locationFilters, locations, user?.subscribedCpoEntities, userCoordinates])

	const { clusters: pointFeatures, supercluster } = useSupercluster({
		bounds: coordinates
			? [
					coordinates.southWest.lng(),
					coordinates.southWest.lat(),
					coordinates.northEast.lng(),
					coordinates.northEast.lat()
				]
			: [0, 0, 0, 0],
		options: {
			maxZoom: MAX_ZOOM - 1,
			radius: CLUSTER_RADIUS
		},
		points,
		zoom
	})

	/**
	 * Merge `locationsNearby` with `locations`.
	 */
	useEffect((): void => {
		if (locationsNearbyQueryStatus === 'success') {
			queryClient.setQueryData<OmniLocation[]>(
				[ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Locations, { fetchAll: true, publish: true }],
				(oldData = []): OmniLocation[] => {
					return mergeLocationsData(oldData, locationsNearby)
				}
			)
		}
	}, [locationsNearby, locationsNearbyQueryStatus, queryClient])

	return { locationsNearbyQueryStatus, pointFeatures, supercluster }
}
