import { OmniLocation } from 'src/_shared/types/omni/location'
import { ClusterProperties, PointFeature } from 'supercluster'

import { LocationDetails } from './types'

export const isPointFeatureCluster = (
	pointFeature: PointFeature<LocationDetails> | PointFeature<ClusterProperties>
): pointFeature is PointFeature<ClusterProperties> => {
	return pointFeature.properties.cluster
}

/**
 * Merges two separate sets of Locations data, overwriting the older data with newer data
 * when there are clashing Location `uid`s.
 * @param {OmniLocation[]} oldData The Locations data fetched prior to `newData`.
 * @param {OmniLocation[]} newData The most recent Locations data.
 * @returns {OmniLocation[]} Merged Locations data.
 */
export const mergeLocationsData = (
	oldData: OmniLocation[],
	newData: OmniLocation[]
): OmniLocation[] => {
	const mergedLocationsMap = [...oldData, ...newData].reduce<Record<string, OmniLocation>>(
		(locationsMap, location): Record<string, OmniLocation> => {
			// Locations from `newData` will override the previous `oldData` if
			// there is an existing `uid` key-value in the map.
			if (location.uid) {
				locationsMap[location.uid] = location
			}
			return locationsMap
		},
		{}
	)
	const mergedLocations = Object.values(mergedLocationsMap)
	return mergedLocations
}
