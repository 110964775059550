import { SVGProps, memo } from 'react'

const LocationPinIcon = ({
	fill = 'none',
	viewBox = '0 0 19 21',
	strokeWidth = 1.5,
	stroke = 'currentColor',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			viewBox={viewBox}
			xmlns={xmlns}
			stroke={stroke}
			strokeWidth={strokeWidth}
			{...props}
		>
			<path d="M18.125 9.43527C18.125 14.1399 12.7344 20.0834 9.5 20.0834C6.26562 20.0834 0.875 14.1399 0.875 9.43527C0.875 4.73062 4.73654 0.916748 9.5 0.916748C14.2635 0.916748 18.125 4.73062 18.125 9.43527Z" />
			<path d="M12.375 9.54175C12.375 11.1296 11.0878 12.4167 9.5 12.4167C7.91218 12.4167 6.625 11.1296 6.625 9.54175C6.625 7.95393 7.91218 6.66675 9.5 6.66675C11.0878 6.66675 12.375 7.95393 12.375 9.54175Z" />
		</svg>
	)
}

const MemoisedLocationPinIcon = memo(LocationPinIcon)

export default MemoisedLocationPinIcon
